const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_FAQS = "SET_FAQS";
const FETCH_FAQS = "FETCH_FAQS";

export const types = {
  SET_ERROR_MESSAGE,
  SET_FAQS,
  FETCH_FAQS,
};

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error: error,
});

export const setFAQs = (faqs) => ({
  type: SET_FAQS,
  faqs: faqs,
});

export const fetchFAQs = (apartment) => ({
  type: FETCH_FAQS,
  apartment: apartment,
});
