import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase, history } from "../../index";
import { setUser } from "../../store/staticReducers/userActions";
import { types, setScreen } from "./actions";

export function* SignOutUser() {
  try {
    yield call({
      context: firebase.auth,
      fn: firebase.auth.signOut
    })
    const userCred = yield call({
      context: firebase.auth,
      fn: firebase.auth.signInAnonymously,
    });
    yield put(setScreen("login"));
  } catch (error) {
    console.error("sign out error " + error);
  }
}

export default function* NavBarSaga() {
  yield all([takeLatest(types.SIGN_OUT_USER, SignOutUser)]);
}
