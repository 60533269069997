import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  root: {
    paddingTop: 50,
    width: "100%",
  },
  maxWidth: {
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    maxWidth: 1100,
    backgroundColor: "white",
  },
  countdownRow: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  countdownColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    textAlign: "left",
    minWidth: 200,
  },
  countdownText: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 25,
  },
  countdownNumber: {
    paddingRight: 10,
    fontSize: "3em",
    marginTop: "0.3em",
    marginBottom: "0.1em",
  },
  countdownColumnRight: {
    textAlign: "right",
    marginBottom: "0.1em",
  },
  countdownBottomText: {
    marginTop: 0,
    fontWeight: "normal",
  },
  countdownTopText: {
    marginBottom: 0,
    fontWeight: "normal",
  },
  greyBackground: {
    backgroundColor: "lightGrey",
    padding: 10,
    paddingLeft: 15,
    marginBottom: 0,
  },
  textPadding: {
    paddingTop: 10,
  },
  apartmentTextMargins: {
    marginLeft: "3%",
    marginRight: "3%",
  },
  titleText: {
    fontSize: "1.3em",
    margin: "0.3em",
    padding: "0.3em",
  },
  boldText: {
    fontWeight: "bold",
  },
  alignImage: {
    verticalAlign: "middle",
    paddingRight: 5,
    paddingBottom: 5,
    height: 32,
    width: 32,
  },
  infoRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginBottom: -10,
    overflow: "hidden",
  },
  infoText: {
    paddingTop: 4,
  },
  icon: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
  },
  iconColumn: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingTop: 3,
    paddingRight: 5,
  },
  linkIcon: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
  },
  centerText: {
    paddingTop: 7,
  },
  apartmentImage: {
    width: "100%",
    ["@media (min-width: 500px)"]: {
      width: 500,
      margin: "auto",
    },
    ["@media (min-width: 750px)"]: {
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  imageHoverStyling: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageContainer: {
    position: "relative",
    ["@media (min-width: 500px)"]: {
      width: 500,
      margin: "auto",
    },
  },
  seeMoreImagesButton: {
    position: "absolute",
    bottom: 15,
    right: 15,
    fontSize: 18,
    ["@media (min-width: 750px)"]: {
      right: 0,
    },
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  apartmentInfoMargins: {
    ["@media (min-width: 750px)"]: {
      marginLeft: "5%",
      marginBottom: 5,
    },
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  listItemContainer: {
    margin: 5,
    marginLeft: "5%",
    marginRight: "5%",
    padding: 10,
    paddingBottom: 25,
    textAlign: "left",
    width: "90%",
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 18,
  },
  button: {
    backgroundColor: "white",
    borderRadius: 10,
    textTransform: "none",
    color: "grey",
    "&:hover": {
      color: "black",
      backgroundColor: "lightGrey",
    },
  },
  halfScreenFlex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  apartmentInfoRow: {
    flex: 1,
  },
  hidden: {
    display: "none",
  },
});

export const modalStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 30,
  },
  paper: {
    position: "absolute",
    height: "35%",
    width: "95%",
    maxWidth: 400,
    backgroundColor: "white",
    outline: "none",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  bodyText: {
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: "center",
    maxWidth: 700,
  },
  hidden: {
    display: "none",
  },
  button: {
    color: "white",
    backgroundColor: "#36ba8c",
    fontSize: 18,
    padding: 10,
    margin: 20,
    "&:hover": {
      color: "#36ba8c",
    },
  },
});
