import { useEffect } from "react";
import { cancel, fork, take } from "redux-saga/effects";
import { store } from "./index.js";
import { sagaMiddleware } from "./store";

const STOP_SAGA = "STOP_SAGA";

function startSaga(key, saga) {
  const newSaga = function* main() {
    const sagaTask = yield fork(saga);
    const { sagaId } = yield take(STOP_SAGA);

    if (sagaId === key) {
      yield cancel(sagaTask);
    }
  };
  sagaMiddleware.run(newSaga);
}

function stopSaga(key) {
  store.dispatch({
    sagaId: key,
    type: STOP_SAGA,
  });
}

const useReduxModule = (key, reducer, saga = function* () {}) => {
  useEffect(() => {
    console.log("Adding module: ", key);
    startSaga(key, saga);
    store.injectReducer(key, reducer);
    return () => {
      console.log("Removing module: ", key);
      store.removeReducer(key);
      stopSaga(key);
    };
  }, [key, reducer, saga]);
};

export default useReduxModule;
