import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase } from "../../index";
import {
  types,
  setMove,
  setResident,
  setLoading,
  setWelcomeVisible,
} from "./actions";
import { setApartment } from "../../store/staticReducers/apartmentActions";
import moment from "moment";

export function* FetchMove({ uid }) {
  yield put(setLoading(true));
  try {
    const moveRef = firebase.firestore.doc(`moves/${uid}`);
    const moveDoc = yield call({
      context: moveRef,
      fn: moveRef.get,
    });
    const moveData = moveDoc.data();
    yield put(setMove(moveData));
  } catch (error) {
    console.error("error with move fetch " + error);
  }
  yield put(setLoading(false));
}

export function* FetchApartment({ apartmentId }) {
  try {
    const apartmentRef = firebase.firestore.doc(`apartments/${apartmentId}`);
    const apartmentDoc = yield call({
      context: apartmentRef,
      fn: apartmentRef.get,
    });
    const apartmentData = apartmentDoc.data();
    yield put(setApartment(apartmentData));
  } catch (error) {
    console.error("error with apartment fetch " + error);
  }
}

export function* FetchResident({ uid, apartmentId }) {
  try {
    const residentRef = firebase.firestore.doc(
      `apartments/${apartmentId}/residents/${uid}`
    );
    const residentDoc = yield call({
      context: residentRef,
      fn: residentRef.get,
    });
    const residentData = residentDoc.data();
    yield put(setResident(residentData));

    if (
      residentData.lastLogin === null ||
      residentData.lastLogin === undefined
    ) {
      yield put(setWelcomeVisible(true));
      const updateLastLogin = residentRef.set(
        {
          lastLogin: moment().valueOf(),
        },
        { merge: true }
      );
      yield put(updateLastLogin());
    }
  } catch (error) {
    console.error("error with resident fetch " + error);
  }
}

export default function* ResidentsPageSaga() {
  yield all([
    takeLatest(types.FETCH_MOVE, FetchMove),
    takeLatest(types.FETCH_APARTMENT, FetchApartment),
    takeLatest(types.FETCH_RESIDENT, FetchResident),
  ]);
}
