import React, { useEffect } from "react";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import ResidentAmenityPageReducer, { INITIAL_STATE } from "./reducers";
import { fetchAmenity } from "./actions";
import ResidentAmenityPageSaga from "./sagas";
import renderClickableText from "../../renderClickableText";
import AmenityInfoRow from "./AmenityInfoRow";
import { styles } from "./styles";
import { Card } from "@material-ui/core";

// module name
const AMENITY_PAGE = "AMENITY_PAGE";

export const AmenityPage = ({
  // url
  location,

  // dispatch
  fetchAmenity,

  // data
  user,
  amenityId,
  name,
  amenityLocation,
  description,
  availability,
  imageURL,
}) => {
  useReduxModule(
    AMENITY_PAGE,
    ResidentAmenityPageReducer,
    ResidentAmenityPageSaga
  );

  const classes = styles();

  useEffect(() => {
    // fetch amenity from url
    if (user) {
      const amenityId = location.search.split("?")[1];
      fetchAmenity(user.uid, amenityId);
    }
  }, [user, fetchAmenity, location.search]);

  if (!amenityId) {
    return <div></div>;
  }

  function displayName() {
    if (name) {
      return <h2 className={classes.bold}>{name}</h2>;
    }
  }

  function displayImage() {
    if (imageURL) {
      return (
        <img src={imageURL} alt="amenity" className={classes.amenityImage} />
      );
    }
  }

  return (
    <div className={`${classes.center} ${classes.navBarPadding}`}>
      <Card elevation={4}>
        {displayName()}
        {displayImage()}
        <div className={classes.leftJustify}>
          {description && (
            <AmenityInfoRow
              title="Description"
              data={renderClickableText(description)}
            />
          )}
          {availability && <AmenityInfoRow title="Hours" data={availability} />}
          {amenityLocation && (
            <AmenityInfoRow title="Location" data={amenityLocation} />
          )}
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { [AMENITY_PAGE]: taskState = INITIAL_STATE } = state;
  return {
    user: state.userState.user,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAmenity: (uid, amenityId) => dispatch(fetchAmenity(uid, amenityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenityPage);
