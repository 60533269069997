import { SET_FORM_LOADING, SET_PREVIOUS_SUBMISSON, SET_FORM_MODE } from "./actions";


export const INITIAL_STATE = {
  prevSubmission: null,
  loading: true,
  loadingMessage: "",
  mode: "new",
}

export const TaskFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FORM_LOADING:
      return state.loading === action.loading ? state : { 
        ...state, 
        loading: action.loading, 
        loadingMessage: action.message 
      };
    case SET_PREVIOUS_SUBMISSON:
      return {
        ...state,
        prevSubmission: action.submission,
      }
    case SET_FORM_MODE:
      return {
        ...state,
        mode: action.mode,
      }
    default: return state;
  }
}