const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_TASKS = "SET_TASKS";
const FETCH_TASKS = "FETCH_TASKS";
const SET_MOVE_IN_TASKS_COMPLETE_MODAL_VISIBLE =
  "SET_MOVE_IN_TASKS_COMPLETE_MODAL_VISIBLE";

export const types = {
  SET_ERROR_MESSAGE,
  SET_TASKS,
  FETCH_TASKS,
  SET_MOVE_IN_TASKS_COMPLETE_MODAL_VISIBLE,
};

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const setTasks = (tasks) => ({
  type: SET_TASKS,
  tasks,
});

export const fetchTasks = (apartmentId, uid) => ({
  type: FETCH_TASKS,
  apartmentId,
  uid,
});

export const setMoveInTasksCompleteModalVisible = (
  moveInTasksCompleteModalVisible
) => ({
  type: SET_MOVE_IN_TASKS_COMPLETE_MODAL_VISIBLE,
  moveInTasksCompleteModalVisible,
});
