import React from "react";
import { styles } from "./styles.js";

const ResidentInfoRow = (props) => {
  const classes = styles();

  return (
    <div className={`${classes.infoRow} ${classes.apartmentTextMargins}`}>
      <div className={classes.iconColumn}>
        <img
          src={props.infoIcon}
          className={classes.icon}
          alt={props.iconAlt}
        />
      </div>
      <p className={classes.infoText}>
        <span className={classes.boldText}>{props.infoLabel}</span>
        {props.infoText}
      </p>
    </div>
  );
};

export default ResidentInfoRow;
