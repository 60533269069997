import React from "react";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { textFieldInputStyles, buttonStyles, errorStyles } from "./styles.js";
import {
  setScreen,
  setErrorMessage,
  setInviteCode,
  fetchInviteCode,
} from "./actions";

function InviteCodeForm({
  goToLogin,
  error,
  clearError,
  setInviteCode,
  clearInviteCode,
  inviteCode,
  fetchInviteCode,
}) {
  const textFieldClasses = textFieldInputStyles()();
  const buttonClasses = buttonStyles()();
  const errorClasses = errorStyles()();

  const onSubmit = (event) => {
    event.preventDefault();
    const code = event.target.code.value;
    fetchInviteCode(code);
  };

  function toggleErrorVisibility() {
    if (error) {
      return errorClasses.errorText;
    } else {
      return `${errorClasses.errorText} ${errorClasses.errorTextHidden}`;
    }
  }

  function handleToUpperCase(event) {
    const input = event.target.value.toUpperCase();
    setInviteCode(input);
  }

  function toggleErrorTextStyling() {
    if (error) {
      return `${textFieldClasses.errorformTextField} ${textFieldClasses.formTextField}`;
    } else {
      return textFieldClasses.formTextField;
    }
  }

  return (
    <div>
      <h3> Enter your invite code </h3>
      <br />
      <form onSubmit={onSubmit}>
        <TextField
          id="code"
          label="Invite Code"
          variant="filled"
          value={inviteCode}
          onChange={handleToUpperCase}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={toggleErrorTextStyling()}
        />
        <p className={toggleErrorVisibility()}>Oops! Invalid invite code.</p>
        <Button
          className={`${buttonClasses.button} ${buttonClasses.submitButton}`}
          type="submit"
          onClick={() => {
            clearError();
          }}
        >
          Submit Code
        </Button>
      </form>
      <Button
        className={`${buttonClasses.button}`}
        variant="outlined"
        onClick={() => {
          clearError();
          clearInviteCode();
          goToLogin();
        }}
      >
        Back
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    error: state.LOGIN_PAGE.error,
    inviteCode: state.LOGIN_PAGE.inviteCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToLogin: () => dispatch(setScreen("login")),
    clearError: () => dispatch(setErrorMessage("")),
    setInviteCode: (inviteCode) => dispatch(setInviteCode(inviteCode)),
    clearInviteCode: () => dispatch(setInviteCode(null)),
    fetchInviteCode: (inviteCode) => dispatch(fetchInviteCode(inviteCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteCodeForm);
