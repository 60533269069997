import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";

export const ProgressCircle = styled(CircularProgress)({
  color: "#36ba8c",
})

const LoadingViewSmall = ({ className, classes, message }) => (
  <div className={className}>
    <ProgressCircle />
    <Typography>{message}</Typography>
  </div>
);

export default LoadingViewSmall;