import React, { useState } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { AppBar, Toolbar, Menu, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { navBarStyles } from "./styles.js";
import { signOutUser } from "./actions";
import useReduxModule from "../../useReduxModule";
import navBarReducer, { INITIAL_STATE } from "./reducers";
import { history } from "../../index.js";
import NavBarSaga from "./sagas";
import userIcon from "./img/baseline_account_circle_white_18dp.png";
import backIcon from "./img/arrow_back_ios-24px.svg";
import dropDownArrow from "./img/arrow_drop_down-24px.svg";

//  module name
const NAV_BAR = "NAV_BAR";

function NavBar({ user, signOutUser }) {
  const navBarClasses = navBarStyles();
  useReduxModule(NAV_BAR, navBarReducer, NavBarSaga);

  function getUserEmail() {
    if (user && user.email !== null) {
      return user.email;
    } else {
      return "Guest";
    }
  }

  const [logoutMenuVisible, setLogoutMenuVisible] = useState(false);
  const [logoutMenuTarget, setLogoutMenuTarget] = useState(null);

  const handleMenuAssignTarget = (event) => {
    setLogoutMenuTarget(event.currentTarget);
  };

  const handleMenuVisibleToggle = () => {
    setLogoutMenuVisible(!logoutMenuVisible);
  };

  const BackButton = () => (
    <div
      className={`${navBarClasses.leftJustify} ${navBarClasses.backButton}`}
      onClick={() => {
        history.push("/resident");
      }}
    >
      <p className={navBarClasses.bottomPadding}>
        <img
          src={backIcon}
          alt="back arrow icon"
          className={navBarClasses.alignImage}
        />
        <span className={navBarClasses.toggleDisplay}>Back</span>
      </p>
    </div>
  );

  function displayHeader() {
    switch (history.location.pathname) {
      case "/amenities":
        return "Amenities";
      case "/gallery":
        return "Photos";
      case "/tasks":
        return "Task Details";
      default:
        return "";
    }
  }

  if (history.location.pathname === "/") {
    return null;
  } else {
    return (
      <AppBar position="static" className={navBarClasses.root} elevation={0}>
        <Toolbar className={navBarClasses.toolBar}>
          {history.location.pathname !== "/resident" &&
            history.location.pathname !== "/" && <BackButton />}
          <h2
            className={`${navBarClasses.centerHeaderText} ${navBarClasses.bottomPadding}`}
          >
            {displayHeader()}
          </h2>
          <div className={navBarClasses.rightJustify}>
            <div>
              <div
                aria-controls="simple-menu"
                aria-haspopup="true"
                onMouseEnter={(event) => {
                  handleMenuVisibleToggle();
                  handleMenuAssignTarget(event);
                }}
                onClick={(event) => {
                  handleMenuVisibleToggle();
                  handleMenuAssignTarget(event);
                }}
              >
                <p className={navBarClasses.bottomPadding}>
                  <img
                    src={userIcon}
                    alt="user icon"
                    className={navBarClasses.alignImage}
                  />
                  <span className={navBarClasses.toggleDisplay}>
                    {getUserEmail()}
                  </span>
                  <img
                    src={dropDownArrow}
                    style={{ fill: "white" }}
                    className={`${navBarClasses.alignImage} ${navBarClasses.icon} ${navBarClasses.toggleDisplay}`}
                    alt="dropdown icon"
                  />
                </p>
                <Menu
                  keepMounted
                  open={logoutMenuVisible}
                  onClose={handleMenuVisibleToggle}
                  anchorEl={logoutMenuTarget}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: 8, horizontal: "center" }}
                >
                  <MenuItem
                    onClick={() => {
                      signOutUser();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  const { [NAV_BAR]: taskState = INITIAL_STATE } = state;
  return {
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(NavBar);
