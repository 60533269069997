import React from "react";
import { Redirect } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { styles } from "./styles.js";
import LoginForm from "./LoginForm";
import InviteCodeForm from "./InviteCodeForm";
import SignUpForm from "./SignUpForm";
import LoadingScreen from "../LoadingScreen";
import logo from "./img/muvnday_logo_color_rtm.png";
import useReduxModule from "../../useReduxModule";
import loginReducer, { INITIAL_STATE } from "./reducers";
import { connect } from "react-redux";
import { setScreen, setErrorMessage } from "./actions";
import LoginSaga from "./sagas";

// module name
const LOGIN_PAGE = "LOGIN_PAGE";

function LoginPage({ screen, inviteCode, isLoading, user }) {
  const classes = styles()();
  useReduxModule(LOGIN_PAGE, loginReducer, LoginSaga);

  if (!isLoading && user && !user.isAnonymous) {
    return <Redirect to="/resident" />;
  }

  const renderScreen = (screen) => {
    switch (screen) {
      case "login":
        return <LoginForm />;
      case "inviteCode":
        return <InviteCodeForm />;
      case "signUp":
        return <SignUpForm />;
      default:
        return <LoginForm />;
    }
  };

  function renderWelcome() {
    if (screen === "signUp") {
      if (inviteCode.name) {
        return (
          <h3 className={classes.welcomeText}>Welcome {inviteCode.name}!</h3>
        );
      } else {
        return <h3 className={classes.welcomeText}>Welcome!</h3>;
      }
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      className={`${classes.root} ${
        screen === "signUp" && classes.signUpPadding
      }`}
    >
      <div className={classes.rowWrapper}>
        <Paper
          className={`${classes.loginWrapper} ${
            screen === "signUp" && classes.reduceWrapperMargin
          }`}
          elevation={0}
        >
          <div className={classes.columnWrapper}>
            {renderWelcome()}
            <div className={screen === "signUp" && classes.logoHidden}>
              <img
                src={logo}
                alt="MuvnDay logo"
                className={`${classes.logo} ${
                  screen === "inviteCode" && classes.inviteCodeLogo
                }
              ${screen === "login" && classes.loginLogo}`}
              />
            </div>
          </div>
          <div
            className={`${classes.columnWrapper} ${classes.columnWrapperForm} 
            ${screen === "inviteCode" && classes.inviteCodeForm}`}
          >
            {renderScreen(screen)}
          </div>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { [LOGIN_PAGE]: taskState = INITIAL_STATE, userState } = state;
  return {
    ...taskState,
    user: userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToInviteCode: () => dispatch(setScreen("inviteCode")),
    goToSignUp: () => dispatch(setScreen("signUp")),
    displayError: () => dispatch(setErrorMessage("loginError")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
