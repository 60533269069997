import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  screen: "gallery",
  photos: null,
  selectedPhoto: null,
  isLoading: false,
};

const galleryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SCREEN:
      return { ...state, screen: action.screen };
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_APARTMENT_PHOTOS:
      return { ...state, photos: action.photos };
    case types.SET_SELECTED_PHOTO:
      return { ...state, selectedPhoto: action.selectedPhoto };
    case types.SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    default:
      return state;
  }
};

export default galleryReducer;
