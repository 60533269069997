import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  greyBackground: {
    backgroundColor: "lightGrey",
    padding: 10,
    paddingLeft: 15,
    marginBottom: 0,
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  buttonStyling: {
    margin: 5,
    marginLeft: "5%",
    marginRight: "5%",
    padding: 10,
    paddingBottom: 25,
    textAlign: "left",
    width: "90%",
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 18,
  },
  listItemContainerOneColumn: {
    width: "100%",
  },
  listItemContainerTwoColumns: {
    width: "50%",
  },
  listItemContainerThreeColumns: {
    width: "33.3%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "left",
    justifyContent: "left",
  },
  topMarginAboveButtons: {
    marginTop: 10,
  },
  centerText: {
    paddingTop: 7,
  },
  amenityRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginBottom: -10,
  },
  iconColumn: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
    paddingTop: 3,
  },
  icon: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
  },
  link: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});
