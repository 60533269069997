import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
    paddingTop: 60,
  },
  listItemContainerOneColumn: {
    width: 350,
    height: 350,
    margin: 2,
    "&:hover": {
      cursor: "pointer",
    },
  },
  listItemContainerTwoColumns: {
    width: 400,
    height: 400,
    margin: 2,
    "&:hover": {
      cursor: "pointer",
    },
  },
  listItemContainerThreeColumns: {
    width: 300,
    height: 300,
    margin: 2,
    "&:hover": {
      cursor: "pointer",
    },
  },
  caption: {
    textAlign: "left",
  },
});

export const modalStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 35,
  },
  paper: {
    position: "absolute",
    marginBottom: 10,
    height: "95%",
    width: "95%",
    maxWidth: 1200,
    backgroundColor: "black",
    outline: "none",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  image: {
    width: "auto",
    height: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  caption: {
    position: "absolute",
    bottom: 0,
    padding: 15,
    textAlign: "center",
    width: "100%",
    color: "white",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  hidden: {
    display: "none",
  },
  rightArrow: {
    position: "absolute",
    right: 0,
    top: "45%",
    height: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
  leftArrow: {
    position: "absolute",
    left: 15,
    top: "45%",
    height: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeButton: {
    position: "absolute",
    left: 10,
    top: 10,
    height: 30,
    "&:hover": {
      cursor: "pointer",
    },
  },
});
