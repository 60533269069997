import React, { useEffect } from "react";
import { styles } from "./styles";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import moveInTasksReducer, { INITIAL_STATE } from "./reducers";
import MoveInTasksSaga from "./sagas";
import { fetchTasks } from "./actions";
import TaskListItem from "./TaskListItem";
import MoveInTasksCompleteModal from "./MoveInTasksCompleteModal";

// module name
const MOVE_IN_TASKS_LIST = "MOVE_IN_TASKS_LIST";

function MoveInTasksList({ user, apartment, tasks, fetchTasks }) {
  const classes = styles();
  useReduxModule(MOVE_IN_TASKS_LIST, moveInTasksReducer, MoveInTasksSaga);

  useEffect(() => {
    if (user && apartment) {
      fetchTasks(apartment.apartmentId, user.uid);
    }
  }, [apartment, user, fetchTasks]);

  function renderTasks() {
    if (tasks) {
      return tasks.map((task) => (
        <TaskListItem
          title={task.title}
          dueDate={task.dueDate}
          completedDate={task.completedDate}
          description={task.description}
          id={task.id}
          key={task.id}
        />
      ));
    }
  }

  return (
    <div>
      <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
        Required Move-in Tasks
      </h3>
      <div className={classes.flexRow}>{renderTasks()}</div>
      <MoveInTasksCompleteModal />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    userState,
    apartmentState,
    [MOVE_IN_TASKS_LIST]: taskState = INITIAL_STATE,
  } = state;
  return {
    user: userState.user,
    apartment: apartmentState.apartment,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (apartmentId, uid) => dispatch(fetchTasks(apartmentId, uid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveInTasksList);
