import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  loadingWrapper: {
    position: "fixed",
    top: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    maxWidth: "50%",
    maxHeight: "100vh",
  },
  spinner: {
    color: "#36ba8c",
  },
});
