import React, { useEffect } from "react";
import { styles } from "./styles";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import faqReducer, { INITIAL_STATE } from "./reducers";
import FAQSaga from "./sagas";
import { fetchFAQs } from "./actions";
import FAQListItem from "./FAQListItem";

// module name
const FAQ_LIST = "FAQ_LIST";

function FAQList({ apartment, faqs, fetchFAQs }) {
  const classes = styles();
  useReduxModule(FAQ_LIST, faqReducer, FAQSaga);

  useEffect(() => {
    if (apartment) {
      fetchFAQs(apartment);
    }
  }, [apartment]);

  function renderFAQs() {
    if (faqs) {
      return faqs.map((faq, index) => (
        <FAQListItem question={faq.question} answer={faq.answer} key={index} />
      ));
    }
  }

  return (
    <div>
      <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
        FAQs
      </h3>
      <div>{renderFAQs()}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { apartmentState, [FAQ_LIST]: taskState = INITIAL_STATE } = state;
  return {
    apartment: apartmentState.apartment,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFAQs: (apartment) => dispatch(fetchFAQs(apartment)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQList);
