import React, { useEffect } from "react";
import { compose } from "recompose";
import { Redirect, withRouter } from "react-router-dom";
import useReduxModule from "../../useReduxModule";
import residentReducer from "./reducers";
import { styles } from "./styles.js";
import ResidentHome from "./ResidentHome";
import LoadingScreen from "../LoadingScreen";
import { INITIAL_STATE } from "./reducers";
import { connect } from "react-redux";
import { fetchMove } from "./actions";
import { setScreen, setErrorMessage } from "./actions";
import ResidentsPageSaga from "./sagas";

// module name
const RESIDENT_PAGE = "RESIDENT_PAGE";

function ResidentPage({ user, screen, fetchMove, isLoading }) {
  const classes = styles();
  useReduxModule(RESIDENT_PAGE, residentReducer, ResidentsPageSaga);

  useEffect(() => {
    if (user && !user.isAnonymous) {
      fetchMove(user.uid);
    }
  }, [user, fetchMove]);

  const renderScreen = (screen) => {
    switch (screen) {
      case "apartmentGallery":
        return <Redirect to="/gallery" />;
      case "login":
        return <Redirect to="/" />;
      default:
        return <ResidentHome />;
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.maxWidth}>{renderScreen(screen)}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { userState, [RESIDENT_PAGE]: taskState = INITIAL_STATE } = state;
  return {
    user: userState.user,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToApartmentGallery: () => dispatch(setScreen("apartmentGallery")),
    displayError: () => dispatch(setErrorMessage("residentError")),
    fetchMove: (uid) => dispatch(fetchMove(uid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ResidentPage);
