// REFACTOR WITH TYPESCRIPT
const SET_SCREEN = "SET_SCREEN";
const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_INVITE_CODE = "SET_INVITE_CODE";
const SET_CONFIRMATION_VISIBLE = "SET_CONFIRMATION_VISIBLE";
const SET_TERMS_AND_PRIVACY_URLS = "SET_TERMS_AND_PRIVACY_URLS";
const LOGIN_USER = "LOGIN_USER";
const FETCH_INVITE_CODE = "FETCH_INVITE_CODE";
const SIGN_UP_USER = "SIGN_UP_USER";
const RESET_PASSWORD = "RESET_PASSWORD";
const FETCH_URLS = "FETCH_URLS";
const SET_LOADING = "SET_LOADING";

export const types = {
  SET_SCREEN,
  SET_ERROR_MESSAGE,
  SET_INVITE_CODE,
  SET_CONFIRMATION_VISIBLE,
  SET_TERMS_AND_PRIVACY_URLS,
  LOGIN_USER,
  FETCH_INVITE_CODE,
  SIGN_UP_USER,
  RESET_PASSWORD,
  FETCH_URLS,
  SET_LOADING,
};

export const setScreen = (screen) => ({
  type: SET_SCREEN,
  screen,
});

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const setInviteCode = (inviteCode) => ({
  type: SET_INVITE_CODE,
  inviteCode,
});

export const setConfirmationVisible = (confirmationVisible) => ({
  type: SET_CONFIRMATION_VISIBLE,
  confirmationVisible,
});

export const loginUser = (email, password) => ({
  type: LOGIN_USER,
  email,
  password,
});

export const fetchInviteCode = (inviteCode) => ({
  type: FETCH_INVITE_CODE,
  inviteCode,
});

export const signUpUser = (
  email,
  password,
  confirmPassword,
  user,
  inviteCode
) => ({
  type: SIGN_UP_USER,
  email,
  password,
  confirmPassword,
  user,
  inviteCode,
});

export const resetPassword = (email) => ({
  type: RESET_PASSWORD,
  email,
});

export const setTermsAndPrivacyUrls = (termsUrl, privacyPolicyUrl) => ({
  type: SET_TERMS_AND_PRIVACY_URLS,
  termsUrl,
  privacyPolicyUrl,
});

export const fetchUrls = () => ({
  type: FETCH_URLS,
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  isLoading,
});
