import React, { useEffect } from "react";
import { styles } from "./styles";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import amenitiesReducer, { INITIAL_STATE } from "./reducers";
import amenitiesSaga from "./sagas";
import { fetchAmenities } from "./actions";
import Amenity from "./Amenity";

// module name
const AMENITIES_LIST = "AMENITIES_LIST";

function AmenityList({ apartment, amenities, fetchAmenities }) {
  const classes = styles();
  useReduxModule(AMENITIES_LIST, amenitiesReducer, amenitiesSaga);

  useEffect(() => {
    if (apartment) {
      fetchAmenities(apartment.apartmentId);
    }
  }, [apartment]);

  function renderAmenities() {
    if (amenities) {
      return amenities.map((amenity) => (
        <Amenity amenity={amenity} key={amenity.id} />
      ));
    }
  }

  return (
    <div>
      <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
        Amenities
      </h3>
      <div className={`${classes.flexRow} ${classes.topMarginAboveButtons}`}>
        {renderAmenities()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { apartmentState, [AMENITIES_LIST]: taskState = INITIAL_STATE } = state;
  return {
    apartment: apartmentState.apartment,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAmenities: (apartmentId) => dispatch(fetchAmenities(apartmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmenityList);
