import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  greyBackground: {
    backgroundColor: "lightGrey",
    padding: 10,
    paddingLeft: 15,
    marginBottom: 0,
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  listItemContainer: {
    margin: 5,
    marginLeft: "5%",
    marginRight: "5%",
    padding: 10,
    paddingTop: 5,
    paddingBottom: 15,
    textAlign: "left",
    width: "90%",
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 18,
  },
  taskRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginTop: -10,
    marginBottom: -20,
    overflow: "hidden",
  },
  completedMargins: {
    marginTop: 2,
    marginBottom: -8,
  },
  textOverflowContainer: {
    overflow: "hidden",
  },
  textOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: 10,
    marginBottom: 0,
    paddingBottom: 0,
  },
  icon: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
    paddingTop: 3,
  },
  iconColumn: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 10,
  },
  dueDate: {
    fontSize: "0.8em",
    color: "red",
    paddingTop: 0,
    marginTop: 0,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "left",
  },
  listItemContainerOneColumn: {
    width: "100%",
  },
  listItemContainerTwoColumns: {
    width: "50%",
  },
  listItemContainerThreeColumns: {
    width: "33.3%",
  },
});

export const modalStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 30,
  },
  paper: {
    position: "absolute",
    height: "35%",
    width: "95%",
    maxWidth: 400,
    backgroundColor: "white",
    outline: "none",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  bodyText: {
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: "center",
    maxWidth: 700,
  },
  hidden: {
    display: "none",
  },
  button: {
    color: "white",
    backgroundColor: "#36ba8c",
    fontSize: 18,
    padding: 10,
    margin: 20,
    "&:hover": {
      color: "#36ba8c",
    },
  },
});
