import React from "react";
import { styles } from "./styles";
import { CircularProgress } from "@material-ui/core";
import logo from "./img/muvnday_logo_color_rtm.png";

export default function LoadingScreen() {
  const classes = styles();

  return (
    <div className={classes.loadingWrapper}>
      <img src={logo} alt="Muvn Day Logo" className={classes.logo} />
      <br />
      <CircularProgress size={"10%"} className={classes.spinner} color="inherit" />
    </div>
  );
}