import { makeStyles } from "@material-ui/core/styles";

export const styles = () =>
  makeStyles({
    root: {
      backgroundColor: "#36ba8c",
      display: "flex",
      flex: 1,
      minheight: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    signUpPadding: {
      paddingBottom: 30,
    },
    loginWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "5%",
      borderRadius: 20,
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      minHeight: 600,
      height: "100%",
    },
    reduceWrapperMargin: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    columnWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 3,
      alignItems: "center",
      justifyContent: "center",
    },
    columnWrapperForm: {
      flex: 2,
      width: "80%",
      padding: "5%",
    },
    rowWrapper: {
      flexDirection: "row",
    },
    logo: {
      width: "80%",
      minWidth: 250,
      paddingRight: "5%",
      paddingLeft: "5%",
    },
    loginLogo: {
      ["@media (max-width: 600px)"]: {
        marginTop: 30,
      },
    },
    inviteCodeLogo: {
      ["@media (max-width: 600px)"]: {
        marginTop: 80,
      },
    },
    inviteCodeForm: {
      ["@media (max-width: 600px)"]: {
        marginBottom: 80,
      },
    },
    logoHidden: {
      ["@media (max-width: 600px)"]: {
        display: "none",
      },
    },
    orDividerWrapper: {
      display: "flex",
      flexDirection: "col",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      color: "grey",
    },
    orDivider: {
      flex: 2,
      backgroundColor: "darkGrey",
    },
    orDividerText: {
      flex: 1,
      fontSize: 20,
    },
    passwordLink: {
      color: "grey",
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        textDecoration: "underline",
        backgroundColor: "white",
      },
    },
    urlLink: {
      color: "black",
      textDecoration: "underline",
      textTransform: "none",
    },
    policyText: {
      fontSize: "0.8em",
    },
    createAccount: {
      marginTop: 0,
    },
    welcomeText: {
      width: "80%",
      minWidth: 270,
      paddingRight: "5%",
      paddingLeft: "5%",
      marginBottom: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    centerText: {
      textAlign: "center",
    },
  });

export const textFieldInputStyles = () =>
  makeStyles({
    formTextField: {
      marginBottom: 10,
      width: "100%",
      border: "1px solid darkGrey",
      borderRadius: 5,
    },
    label: {
      "&$focused": {
        color: "grey",
      },
    },
    focused: {
      color: "grey",
    },
    errorformTextField: {
      border: "1px solid red",
    },
  });

export const buttonStyles = () =>
  makeStyles({
    button: {
      borderRadius: 30,
      marginBottom: 10,
      textTransform: "none",
      color: "grey",
      width: "100%",
      minWidth: 200,
      fontSize: "1.05em",
      padding: "2%",
    },
    submitButton: {
      backgroundColor: "#36ba8c",
      color: "white",
      outline: "none",
      "&:hover": {
        color: "darkGrey",
      },
    },
    modalCancelButton: {
      minWidth: 50,
      width: "30%",
      borderRadius: 10,
      border: 0,
      color: "#36ba8c",
      "&:hover": {
        color: "grey",
        backgroundColor: "white",
      },
    },
    modalSubmitButton: {
      minWidth: 50,
      width: "30%",
      borderRadius: 10,
    },
    modalRightJustify: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });

export const errorStyles = () =>
  makeStyles({
    errorText: {
      color: "red",
      fontSize: "0.8em",
    },
    errorTextHidden: {
      visibility: "hidden",
    },
  });

export const modalStyles = () =>
  makeStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "absolute",
      height: "50%",
      width: "60%",
      maxHeight: 300,
      maxWidth: 400,
      backgroundColor: "white",
      outline: "none",
      borderRadius: 10,
      padding: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    hidden: {
      display: "none",
    },
  });
