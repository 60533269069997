import { makeStyles, withStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

export const styles = makeStyles({
  greyBackground: {
    backgroundColor: "lightGrey",
    padding: 10,
    paddingLeft: 15,
    marginBottom: 0,
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  accordionMain: {
    border: "1px solid lightGrey",
  },
  question: {
    fontWeight: "bold",
  },
  downArrow: {
    marginLeft: "auto",
  },
  flipArrow: {
    transform: "rotate(180deg)",
  },
});
