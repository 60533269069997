import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Redirect, withRouter } from "react-router-dom";
import useReduxModule from "../../useReduxModule";
import { fetchGalleryPhotos } from "./actions";
import galleryReducer, { INITIAL_STATE } from "./reducers";
import { styles } from "./styles.js";
import { GridList } from "@material-ui/core";
import ApartmentGalleryPhoto from "./ApartmentGalleryPhoto";
import PhotoDetailsModal from "./PhotoDetailsModal";
import LoadingScreen from "../LoadingScreen";
import ApartmentGallerySaga from "./sagas";

// module name
const APARTMENT_GALLERY = "APARTMENT_GALLERY";

function ApartmentGallery({
  user,
  screen,
  photos,
  selectedPhoto,
  fetchGalleryPhotos,
  isLoading,
}) {
  const classes = styles();
  useReduxModule(APARTMENT_GALLERY, galleryReducer, ApartmentGallerySaga);

  const renderScreen = (screen) => {
    switch (screen) {
      case "resident":
        return <Redirect to="/resident" />;
      case "login":
        return <Redirect to="/" />;
      default:
        return <GridList className={classes.root}>{renderGallery()}</GridList>;
    }
  };

  useEffect(() => {
    if (user) {
      fetchGalleryPhotos(user.uid);
    }
  }, [user, fetchGalleryPhotos]);

  function renderGallery() {
    if (photos) {
      return photos.map((photo) => (
        <ApartmentGalleryPhoto
          photo={photo}
          url={photo.url}
          caption={photo.caption}
          photoDetailsVisible={photoDetailsVisible}
          handlePhotoDetailsToggle={handlePhotoDetailsToggle}
          key={photo.id}
        />
      ));
    }
  }

  const [photoDetailsVisible, setPhotoDetailsVisible] = useState(false);

  function handlePhotoDetailsToggle() {
    setPhotoDetailsVisible(!photoDetailsVisible);
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {renderScreen(screen)}
      {photoDetailsVisible && (
        <PhotoDetailsModal
          photo={selectedPhoto}
          photoDetailsVisible={photoDetailsVisible}
          handlePhotoDetailsToggle={handlePhotoDetailsToggle}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    userState,
    apartmentState,
    [APARTMENT_GALLERY]: taskState = INITIAL_STATE,
  } = state;
  return {
    user: userState.user,
    apartment: apartmentState.apartment,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGalleryPhotos: (uid) => dispatch(fetchGalleryPhotos(uid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ApartmentGallery);
