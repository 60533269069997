export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_AMENITY = "SET_AMENITY";
export const FETCH_AMENITY = "FETCH_AMENITY";

export const types = {
  SET_ERROR_MESSAGE,
  SET_AMENITY,
  FETCH_AMENITY,
};

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const setAmenity = (amenity) => ({
  type: SET_AMENITY,
  amenity,
});

export const fetchAmenity = (uid, amenityId) => ({
  type: FETCH_AMENITY,
  uid,
  amenityId,
});
