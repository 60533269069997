// react redux
import React from "react";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import SnacksReducer, { INITIAL_STATE } from "./reducers";
import { removeSnack, addSnack } from "./actions";

import { Snackbar } from "@material-ui/core";

const SNACKS = "SNACKS";
function* sagas() { }

export const Snacks = ({
  // state
  snacks,

  // dispatch
  removeSnack,
}) => {
  useReduxModule(SNACKS, SnacksReducer, sagas);
  if (snacks.length === 0) { return "" }
  const snack = snacks[0];
  return (<span>
    <Snackbar
      key={snack.id}
      id={snack.id}
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={() => { removeSnack(snack.id) }}
      message={snack.message}
      autoHideDuration={5000}
    />
  </span>);

};

const mapStateToProps = state => {
  const { [SNACKS]: snacksState = INITIAL_STATE } = state
  return snacksState;
}

const mapDispatchToProps = dispatch => ({
  removeSnack: snackId => dispatch(removeSnack(snackId)),
  addSnack: snack => dispatch(addSnack(snack)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Snacks);