import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase } from "../../index";
import { types, setTeamMembers } from "./actions";

export function* FetchTeamMembers({ apartmentId }) {
  try {
    const teamMembersRef = firebase.firestore.collection(
      `apartments/${apartmentId}/team/`
    );
    const teamMembersDoc = yield call({
      context: teamMembersRef,
      fn: teamMembersRef.get,
    });

    const teamMembersData = teamMembersDoc.docs.map((doc) => doc.data());
    if (teamMembersData) {
      yield put(setTeamMembers(teamMembersData));
    }
  } catch (error) {
    console.error("error with teamMembers fetch " + error);
  }
}

export default function* TeamMembersSaga() {
  yield all([takeLatest(types.FETCH_TEAM_MEMBERS, FetchTeamMembers)]);
}
