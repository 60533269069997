import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase } from "../../index";
import { types, setApartmentPhotos, setLoading } from "./actions";

export function* FetchGalleryPhotos({ uid }) {
  yield put(setLoading(true));
  try {
    const apartmentData = yield call(
      {
        context: firebase,
        fn: firebase.getUserApartment,
      },
      uid
    );
    if (apartmentData) {
      const photoRef = firebase.firestore.collection(
        `apartments/${apartmentData.apartmentId}/apartmentPhotos`
      );
      const photoDoc = yield call({
        context: photoRef,
        fn: photoRef.get,
      });
      const photoData = photoDoc.docs
        .map((doc) => doc.data())
        .sort(function (a, b) {
          return a.timeStamp - b.timeStamp;
        });
      if (photoData) {
        yield put(setApartmentPhotos(photoData));
      }
    }
  } catch (error) {
    console.error("error with gallery photo fetch " + error);
  }
  yield put(setLoading(false));
}

export default function* ApartmentGallerySaga() {
  yield all([takeLatest(types.FETCH_GALLERY_PHOTOS, FetchGalleryPhotos)]);
}
