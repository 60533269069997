import React, { useEffect } from "react";
import { styles } from "./styles";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import phoneNumbersReducer, { INITIAL_STATE } from "./reducers";
import PhoneNumbersSaga from "./sagas";
import { fetchPhoneNumbers } from "./actions";
import PhoneNumber from "./PhoneNumber";

// module name
const PHONE_NUMBER_LIST = "PHONE_NUMBER_LIST";

function PhoneNumberList({ apartment, phoneNumbers, fetchPhoneNumbers }) {
  const classes = styles();
  useReduxModule(PHONE_NUMBER_LIST, phoneNumbersReducer, PhoneNumbersSaga);

  useEffect(() => {
    if (apartment) {
      fetchPhoneNumbers(apartment.apartmentId);
    }
  }, [apartment]);

  function renderPhoneNumbers() {
    if (phoneNumbers) {
      return phoneNumbers.map((phoneNumber) => (
        <PhoneNumber
          title={phoneNumber.title}
          phoneNumber={phoneNumber.phoneNumber}
          key={phoneNumber.id}
        />
      ));
    }
  }

  return (
    <div>
      <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
        Important Phone Numbers
      </h3>
      <div className={`${classes.flexRow} ${classes.topMarginAboveButtons}`}>
        {renderPhoneNumbers()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    apartmentState,
    [PHONE_NUMBER_LIST]: taskState = INITIAL_STATE,
  } = state;
  return {
    apartment: apartmentState.apartment,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPhoneNumbers: (apartmentId) =>
      dispatch(fetchPhoneNumbers(apartmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberList);
