import React from "react";
import { styles } from "./styles.js";

const AmenityInfoRow = (props) => {
  const classes = styles();

  return (
    <div>
      <h3 className={classes.bold}>{props.title}</h3>
      <p className={classes.indentText}>{props.data}</p>
    </div>
  );
};

export default AmenityInfoRow;
