import React from "react";
import { Button } from "@material-ui/core";
import { styles } from "./styles.js";

const ApartmentInfoRow = (props) => {
  const classes = styles();

  function formatLink() {
    switch (props.type) {
      case "web":
        if (props.infoText.slice(0, 4) === "http") {
          return props.infoText;
        } else {
          return `http://${props.infoText}`;
        }
      case "address":
        return `https://maps.google.com/?q=${props.infoText}`;
      case "phone":
        return `tel: ${props.infoText}`;
      case "email":
        return `mailto: ${props.infoText}`;
      default:
        return <p>Error</p>;
    }
  }

  return (
    <div className={classes.apartmentInfoMargins}>
      <a
        href={formatLink()}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <Button variant="contained" className={classes.listItemContainer}>
          <div className={classes.infoRow}>
            <div className={classes.iconColumn}>
              <img
                src={props.infoIcon}
                className={classes.icon}
                alt={props.iconAlt}
              />
            </div>
            <div className={classes.centerText}>
              <div>{props.infoText}</div>
            </div>
          </div>
        </Button>
      </a>
    </div>
  );
};

export default ApartmentInfoRow;
