import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setUser } from "../../store/staticReducers/userActions";

import MoveInHeader from "./MoveInHeader";
import ApartmentDetails from "./ApartmentDetails";
import MoveInTasksList from "../MoveInTasksList/MoveInTasksList";
import FAQList from "../FAQList/FAQList";
import PhoneNumberList from "../PhoneNumberList/PhoneNumberList";
import AmenitiesList from "../Amenities/AmenitiesList";
import TeamMembersList from "../TeamMembersList/TeamMembersList";
import WelcomeModal from "./WelcomeModal";

function ResidentHome({ user, history }) {
  return (
    <div>
      <MoveInHeader />
      <ApartmentDetails />
      <MoveInTasksList />
      <FAQList />
      <PhoneNumberList />
      <TeamMembersList />
      <AmenitiesList />
      <WelcomeModal />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { userState } = state;
  return {
    user: userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOutUser: () => dispatch(setUser(null)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ResidentHome);
