import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { setScreen, fetchApartment, fetchResident } from "./actions";
import { INITIAL_STATE } from "./reducers";
import { history } from "../../index.js";
import { styles } from "./styles.js";
import { Button } from "@material-ui/core";
import ApartmentInfoRow from "./ApartmentInfoRow";
import ApartmentAbout from "./ApartmentAbout";
import ResidentDetails from "./ResidentDetails";
import globeIcon from "./img/public-24px.svg";
import locationIcon from "./img/location_on-24px.svg";
import phoneIcon from "./img/phone-24px.svg";
import messageIcon from "./img/mail-24px.svg";

function ApartmentDetails({
  user,
  move,
  apartment,
  goToApartmentGallery,
  fetchApartment,
  fetchResident,
}) {
  const classes = styles();

  useEffect(() => {
    if (user && move) {
      fetchApartment(move.apartmentId);
      fetchResident(user.uid, move.apartmentId);
    }
  }, [move, user, fetchApartment, fetchResident]);

  function renderSeeMoreButton() {
    if (apartment.galleryEnabled) {
      return (
        <Button
          className={`${classes.seeMoreImagesButton} ${classes.button}`}
          onClick={() => {
            goToApartmentGallery();
            history.push("/gallery");
          }}
        >
          See more
        </Button>
      );
    }
  }

  function renderClickableImage() {
    if (apartment.galleryEnabled) {
      goToApartmentGallery();
      history.push("/gallery");
    }
  }

  function renderImageHoverStyling() {
    if (apartment.galleryEnabled) {
      return classes.imageHoverStyling;
    }
  }

  function displayWebsite() {
    if (apartment.web) {
      return (
        <ApartmentInfoRow
          type="web"
          infoText={apartment.web}
          infoIcon={globeIcon}
          iconAlt="globe icon"
        />
      );
    }
  }

  function displayAddress() {
    if (apartment.address) {
      return (
        <ApartmentInfoRow
          type="address"
          infoText={apartment.displayAddress}
          infoIcon={locationIcon}
          iconAlt="location pin icon"
        />
      );
    }
  }

  function displayPhone() {
    if (apartment.phone) {
      return (
        <ApartmentInfoRow
          type="phone"
          infoText={apartment.phone}
          infoIcon={phoneIcon}
          iconAlt="phone icon"
        />
      );
    }
  }

  function displayEmail() {
    if (apartment.email) {
      return (
        <ApartmentInfoRow
          type="email"
          infoText={apartment.email}
          infoIcon={messageIcon}
          alt="messageIcon"
        />
      );
    }
  }

  function displayDetails() {
    if (move) {
      if (apartment) {
        return (
          <div className={classes.centerItems}>
            <p className={classes.titleText}>{apartment.name}</p>
            <div className={classes.halfScreenFlex}>
              <div>
                <div className={classes.imageContainer}>
                  <img
                    src={apartment.imageURL}
                    className={`${
                      classes.apartmentImage
                    } ${renderImageHoverStyling()}`}
                    alt="apartment"
                    onClick={() => {
                      renderClickableImage();
                    }}
                  />
                  {renderSeeMoreButton()}
                </div>
              </div>
              <div className={classes.apartmentInfoRow}>
                {displayWebsite()}
                {displayAddress()}
                {displayPhone()}
                {displayEmail()}
              </div>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <div>
      <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
        Moving To
      </h3>
      <div>{displayDetails()}</div>
      <ApartmentAbout />
      <ResidentDetails />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { userState, apartmentState, RESIDENT_PAGE = INITIAL_STATE } = state;
  return {
    user: userState.user,
    move: RESIDENT_PAGE.move,
    apartment: apartmentState.apartment,
    screen: RESIDENT_PAGE.screen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToApartmentGallery: () => dispatch(setScreen("apartmentGallery")),
    fetchApartment: (apartmentId) => dispatch(fetchApartment(apartmentId)),
    fetchResident: (uid, apartmentId) =>
      dispatch(fetchResident(uid, apartmentId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ApartmentDetails);
