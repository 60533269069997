import { put, all, takeLatest } from "redux-saga/effects";
import { types, setFAQs } from "./actions";

export function* FetchFAQs({ apartment }) {
  try {
    const faqArray = apartment.faqs;
    if (faqArray) {
      yield put(setFAQs(faqArray));
    }
  } catch (error) {
    console.error("error with faq fetch " + error);
  }
}

export default function* FAQSaga() {
  yield all([takeLatest(types.FETCH_FAQS, FetchFAQs)]);
}
