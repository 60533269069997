import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  teamMembers: null,
  selectedTeamMember: null,
};

const teamMembersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_TEAM_MEMBERS:
      return { ...state, teamMembers: action.teamMembers };
    case types.SET_SELECTED_TEAM_MEMBER:
      return { ...state, selectedTeamMember: action.selectedTeamMember };
    default:
      return state;
  }
};

export default teamMembersReducer;
