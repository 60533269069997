import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import StaticReducers from "./staticReducers";

export const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
  const store = createStore(
    combineReducers({ ...StaticReducers }),
    applyMiddleware(sagaMiddleware)
  );

  store.asyncReducers = {};

  store.injectReducer = function (key, asyncReducer) {
    store.asyncReducers[key] = asyncReducer;
    const updatedReducer = combineReducers({
      ...StaticReducers,
      ...store.asyncReducers,
    });
    store.replaceReducer(updatedReducer);
  };

  store.removeReducer = function (key) {
    delete store.asyncReducers[key];
    delete store.getState()[key];
  };

  return store;
}
