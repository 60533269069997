import React from "react";
import { Button, TextField, Modal, Paper } from "@material-ui/core";
import {
  styles,
  textFieldInputStyles,
  buttonStyles,
  errorStyles,
  modalStyles,
} from "./styles.js";
import {
  setErrorMessage,
  resetPassword,
  setConfirmationVisible,
} from "./actions";
import { connect } from "react-redux";
import { INITIAL_STATE } from "./reducers";

function ForgotPasswordModal({
  forgotPasswordVisible,
  handleForgotPasswordClose,
  error,
  confirmationVisible,
  clearError,
  resetPassword,
  setConfirmationVisible,
}) {
  const classes = styles()();
  const textFieldClasses = textFieldInputStyles()();
  const buttonClasses = buttonStyles()();
  const errorClasses = errorStyles()();
  const modalClasses = modalStyles()();

  const handlePasswordResetEmail = (event) => {
    event.preventDefault();
    const resetEmail = event.target.resetEmail.value;
    resetPassword(resetEmail);
  };

  function toggleModalErrorVisibility() {
    if (error) {
      return errorClasses.errorText;
    } else {
      return `${errorClasses.errorText} ${errorClasses.errorTextHidden}`;
    }
  }

  function toggleModalErrorTextStyling() {
    if (error) {
      return `${textFieldClasses.errorformTextField} ${textFieldClasses.formTextField}`;
    } else {
      return textFieldClasses.formTextField;
    }
  }

  function toggleResetForm() {
    if (confirmationVisible) {
      return modalClasses.hidden;
    }
  }

  function toggleConfirmation() {
    if (!confirmationVisible) {
      return modalClasses.hidden;
    }
  }

  return (
    <Modal
      open={forgotPasswordVisible}
      onClose={() => {
        handleForgotPasswordClose();
        setConfirmationVisible();
      }}
      className={modalClasses.root}
    >
      <Paper className={modalClasses.paper} elevation={10}>
        <div className={toggleResetForm()}>
          <h2 className={classes.centerText}>FORGOT YOUR PASSWORD?</h2>
          <p>Not a big deal! We can email you a link to reset your password.</p>
          <form onSubmit={handlePasswordResetEmail}>
            <TextField
              id="resetEmail"
              label="Email Address"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{
                classes: {
                  root: textFieldClasses.label,
                  focused: textFieldClasses.focused,
                },
              }}
              className={toggleModalErrorTextStyling()}
            />
            <br />
            <p class={toggleModalErrorVisibility()}>
              Oops! The email entered doesn't exist.
            </p>
            <div className={buttonClasses.modalRightJustify}>
              <Button
                className={`${buttonClasses.button} ${buttonClasses.modalCancelButton}`}
                variant="outlined"
                onClick={() => {
                  clearError();
                  handleForgotPasswordClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className={`${buttonClasses.button} ${buttonClasses.submitButton} ${buttonClasses.modalSubmitButton}`}
                type="submit"
              >
                Send
              </Button>
            </div>
          </form>
        </div>
        <div className={`${classes.centerText} ${toggleConfirmation()}`}>
          <h2>Your email has been sent!</h2>
          <Button
            className={`${buttonClasses.submitButton} ${buttonClasses.modalSubmitButton}`}
            onClick={() => {
              clearError();
              handleForgotPasswordClose();
              setConfirmationVisible();
            }}
          >
            Close
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { LOGIN_PAGE = INITIAL_STATE } = state;
  return { confirmationVisible: LOGIN_PAGE.confirmationVisible };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch(setErrorMessage("")),
    resetPassword: (email) => dispatch(resetPassword(email)),
    setConfirmationVisible: (confirmationVisible) =>
      dispatch(setConfirmationVisible(confirmationVisible)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
