import React from "react";
import { connect } from "react-redux";
import { INITIAL_STATE } from "./reducers";
import { setWelcomeVisible } from "./actions";
import { modalStyles } from "./styles";
import { Modal, Paper, Button } from "@material-ui/core";

const WelcomeModal = ({ apartment, welcomeVisible, setWelcomeVisible }) => {
  const modalClasses = modalStyles();

  if (apartment) {
    return (
      <Modal
        open={welcomeVisible}
        onClose={() => {
          setWelcomeVisible(false);
        }}
        className={modalClasses.root}
      >
        <Paper className={modalClasses.paper}>
          <h2>Welcome!</h2>
          <div className={modalClasses.bodyText}>{apartment.welcome}</div>
          <Button
            onClick={() => {
              setWelcomeVisible(false);
            }}
            className={modalClasses.button}
          >
            THANKS
          </Button>
        </Paper>
      </Modal>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  const { apartmentState, RESIDENT_PAGE = INITIAL_STATE } = state;
  return {
    apartment: apartmentState.apartment,
    welcomeVisible: RESIDENT_PAGE.welcomeVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWelcomeVisible: (welcomeVisible) =>
      dispatch(setWelcomeVisible(welcomeVisible)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
