import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase } from "../../index";
import { types, setTasks, setMoveInTasksCompleteModalVisible } from "./actions";
import moment from "moment";

export function* FetchTasks({ apartmentId, uid }) {
  try {
    const taskRef = firebase.firestore.collection(
      `apartments/${apartmentId}/residents/${uid}/tasks`
    );
    const taskDoc = yield call({
      context: taskRef,
      fn: taskRef.get,
    });
    const taskData = taskDoc.docs
      .map((doc) => doc.data())
      .sort(function (a, b) {
        return a.dueDate - b.dueDate;
      });
    if (taskData) {
      yield put(setTasks(taskData));
    }

    const residentRef = firebase.firestore.doc(
      `apartments/${apartmentId}/residents/${uid}`
    );
    const residentDoc = yield call({
      context: residentRef,
      fn: residentRef.get,
    });
    const residentData = residentDoc.data();
    if (residentData.completedTasks === residentData.numTasks) {
      if (
        residentData.dateTasksCompleted === null ||
        residentData.dateTasksCompleted === undefined
      ) {
        yield put(setMoveInTasksCompleteModalVisible(true));
        const updateDateTasksCompleted = residentRef.set(
          {
            dateTasksCompleted: moment().valueOf(),
          },
          { merge: true }
        );
        yield put(updateDateTasksCompleted());
      }
    }
  } catch (error) {
    console.error("error with task fetch " + error);
  }
}

export default function* MoveInTasksSaga() {
  yield all([takeLatest(types.FETCH_TASKS, FetchTasks)]);
}
