import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase, history } from "../../index";
import * as Firebase from "firebase/app";
import {
  types,
  setErrorMessage,
  setScreen,
  setInviteCode,
  setConfirmationVisible,
  setTermsAndPrivacyUrls,
  setLoading,
} from "./actions";
import { addSnack } from "../snacks/actions";

export function* SignInUser({ email, password }) {
  try {
    const userCred = yield call(
      {
        context: firebase.auth,
        fn: firebase.auth.signInWithEmailAndPassword,
      },
      email,
      password
    );
    yield put(setScreen("resident"));
  } catch (error) {
    yield put(setErrorMessage("loginError"));
  }
}

export function* FetchInviteCode({ inviteCode }) {
  try {
    if (inviteCode) {
      const inviteCodeRef = firebase.firestore.doc(`inviteCodes/${inviteCode}`);
      const inviteCodeDoc = yield call({
        context: inviteCodeRef,
        fn: inviteCodeRef.get,
      });

      const inviteCodeData = inviteCodeDoc.data();
      if (inviteCodeData) {
        yield put(setInviteCode(inviteCodeData));
        yield put(setScreen("signUp"));
      } else {
        yield put(setErrorMessage("loginError"));
      }
    } else {
      yield put(setErrorMessage("loginError"));
    }
  } catch (error) {
    yield put(setErrorMessage("loginError"));
  }
}

export function* SignUpUser({
  email,
  password,
  confirmPassword,
  user,
  inviteCode,
}) {
  if (password !== confirmPassword) {
    yield put(setErrorMessage("passwordMatch"));
  } else if (password < 6) {
    yield put(setErrorMessage("passwordLength"));
  } else {
    yield put(setLoading(true));
    if (user && user.isAnonymous) {
      try {
        const userCred = Firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );
        // link email and password with user
        const cred = yield call(
          {
            context: user,
            fn: user.linkWithCredential,
          },
          userCred
        );
        // call setUpMoveWithInviteCode cloud function
        const callableResult = yield call(
          {
            context: firebase.functions,
            fn: firebase.callableTimeout,
          },
          "setUpMoveWithInviteCode",
          { inviteCode }
        );
        if (callableResult !== undefined && callableResult !== "timeout") {
          yield put(setScreen("resident"));
        } else {
          throw new Error("internal error");
        }
      } catch (error) {
        // yield put(setErrorMessage("emailInUse"));
        yield put(addSnack("signUpError", error.message, "error"));
        console.error(error);
      }
    } else {
      if (user && !user.isAnonymous) {
        history.push("./resident");
        yield put(setScreen("resident"));
      }
    }
    yield put(setLoading(false));
  }
}

export function* FetchUrls() {
  try {
    const urlsRef = firebase.firestore
      .collection("appSettings")
      .doc("termsAndPrivacyPolicy");
    const urlsDoc = yield call({
      context: urlsRef,
      fn: urlsRef.get,
    });
    const termsUrlData = urlsDoc.data().termsAndConditionsURL;
    const privacyPolicyUrlData = urlsDoc.data().privacyPolicyURL;
    yield put(setTermsAndPrivacyUrls(termsUrlData, privacyPolicyUrlData));
  } catch (error) {
    console.error("error retching ToS and Privacy links " + error);
  }
}

export function* ResetPassword({ email }) {
  try {
    if (email) {
      yield call(
        {
          context: firebase.auth,
          fn: firebase.auth.sendPasswordResetEmail,
        },
        email
      );
      yield put(setConfirmationVisible(true));
    } else {
      yield put(setErrorMessage("resetEmailError"));
    }
  } catch (error) {
    yield put(setErrorMessage("resetEmailError"));
  }
}

export default function* LoginSaga() {
  yield all([
    takeLatest(types.LOGIN_USER, SignInUser),
    takeLatest(types.FETCH_INVITE_CODE, FetchInviteCode),
    takeLatest(types.SIGN_UP_USER, SignUpUser),
    takeLatest(types.RESET_PASSWORD, ResetPassword),
    takeLatest(types.FETCH_URLS, FetchUrls),
  ]);
}
