const SET_USER = "SET_USER";

export const types = {
  SET_USER,
};

export const setUser = (user) => ({
  type: SET_USER,
  user: user,
});
