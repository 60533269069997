const SET_APARTMENT = "SET_APARTMENT";

export const types = {
  SET_APARTMENT,
};

export const setApartment = (apartment) => ({
  type: SET_APARTMENT,
  apartment: apartment,
});
