import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  greyBackground: {
    backgroundColor: "lightGrey",
    padding: 10,
    paddingLeft: 15,
    marginBottom: 0,
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  listItemContainerOneColumn: {
    width: "100%",
  },
  listItemContainerTwoColumns: {
    width: "50%",
  },
  listItemContainerThreeColumns: {
    width: "33.3%",
  },
  buttonStyling: {
    margin: 5,
    marginLeft: "5%",
    marginRight: "5%",
    paddingBottom: 15,
    textAlign: "left",
    width: "90%",
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 18,
  },
  centerText: {
    paddingTop: 3,
  },
  teamMemberRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginBottom: -10,
    overflow: "hidden",
  },
  iconContainer: {
    height: 64,
    width: 64,
    overflow: "hidden",
    borderRadius: "100%",
    marginRight: 10,
  },
  iconColumn: {
    height: 64,
    width: 64,
    alignSelf: "center",
    objectFit: "cover",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "left",
    justifyContent: "left",
  },
  topMarginAboveButtons: {
    marginTop: 10,
  },
  position: {},
  name: {
    fontSize: "0.8em",
  },
});

export const modalStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 30,
  },
  paper: {
    position: "absolute",
    height: "95%",
    width: "95%",
    maxWidth: 500,
    backgroundColor: "white",
    outline: "none",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  scrollable: {
    position: "absolute",
    height: "95%",
    width: "80%",
    maxWidth: 500,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    overflowX: "hidden",
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transparent",
    },
  },
  iconContainer: {
    height: 128,
    width: 128,
    overflow: "hidden",
    borderRadius: "100%",
  },
  photo: {
    height: 128,
    width: 128,
    objectFit: "cover",
  },
  leftJustify: {
    paddingRight: 40,
    paddingLeft: 40,
    textAlign: "left",
    width: "80%",
    maxWidth: 700,
  },
  boldTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  modalData: {
    fontSize: 18,
    paddingLeft: 15,
  },
  hidden: {
    display: "none",
  },
  rightArrow: {
    position: "absolute",
    right: 0,
    top: "45%",
    height: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
  leftArrow: {
    position: "absolute",
    left: 15,
    top: "45%",
    height: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeButton: {
    position: "absolute",
    left: 10,
    top: 10,
    height: 30,
    "&:hover": {
      cursor: "pointer",
    },
  },
});
