import React from "react";

export default function renderClickableText(text) {
  const urlReg = /(https?:\/\/[^\s]+|http?:\/\/[^\s]+|www.?[^\s]+|[2-9]\d{2}-\d{3}-\d{4}|\(\d{3}\)\s\d{3}-\d{4}|(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))/g;
  const checkHttp = /(https?:\/\/[^\s]+|http?:\/\/[^\s]+)/g;
  const checkPhone = /([2-9]\d{2}-\d{3}-\d{4}|\(\d{3}\)\s\d{3}-\d{4})/g;
  const checkEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
  return text.split(urlReg).map((section) => {
    switch (true) {
      case section.match(checkHttp) !== null:
        return (
          <a target="_blank" rel="noopener noreferrer" href={section}>
            {section}
          </a>
        );
      case section.match(checkPhone) !== null:
        return (
          <a target="_blank" rel="noopener noreferrer" href={`tel: ${section}`}>
            {section}
          </a>
        );
      case section.match(checkEmail) !== null:
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto: ${section}`}
          >
            {section}
          </a>
        );
      case section.match(urlReg) !== null:
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`http://${section}`}
          >
            {section}
          </a>
        );
      default:
        return section;
    }
  });
}
