import React, { useState } from "react";
import columnMediaQuery from "../../columnMediaQuery";
import { styles } from "./styles";
import { Button } from "@material-ui/core";
import teamIcon from "./img/account_circle-24px.svg";

const TeamMember = (props) => {
  const classes = styles();

  const [numberOfColumns, setNumberOfColumns] = useState(
    classes.listItemContainerOneColumn
  );

  function handleNumberOfColumnsChange(updatedValue) {
    setNumberOfColumns(updatedValue);
  }

  columnMediaQuery(
    classes.listItemContainerOneColumn,
    classes.listItemContainerTwoColumns,
    classes.listItemContainerThreeColumns,
    handleNumberOfColumnsChange,
    numberOfColumns
  );

  return (
    <div className={numberOfColumns}>
      <Button
        variant="contained"
        className={classes.buttonStyling}
        onClick={() => {
          props.setSelectedTeamMember(props.teamMember);
          props.handleTeamMemberModalToggle();
        }}
      >
        <div className={classes.teamMemberRow}>
          <div className={classes.iconContainer}>
            <img
              src={
                props.teamMember.imageURL ? props.teamMember.imageURL : teamIcon
              }
              alt="team member"
              className={classes.iconColumn}
            />
          </div>
          <div className={classes.centerText}>
            <div className={classes.position}>{props.teamMember.position}</div>
            <div className={classes.name}>{props.teamMember.name}</div>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default TeamMember;
