import React from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { TaskFormStyles } from "./styles";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 10
  },
  input: {
    display: "none",
    width: "100%"
  },
  button: {
    color: "#36ba8c",
    width: "100%",
  },
  reqField: {
    marginLeft: "67px",
    marginBottom: "-25px",
  }
});

const ImageUpload = (props) => {
  const { formState, classes, img, handleImgPreview, handleUploadImg } = props;
  const handleUploadClick = event => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      //extract base 64 string from file for Firestore
      var base64string = reader.result.split(',')[1];
      handleUploadImg(base64string);
      //return image to dialog for temporary display
      handleImgPreview([reader.result]);
    };

  };
  const formStyles = TaskFormStyles();

  //Render state without image preview
  const renderInitialState = () => {
    return (
      <div className={formStyles.fileFieldUpload}>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
          <p className={classes.reqField}></p>
          <Button component="span" variant="outlined" className={formStyles.fileFormButton}>
            <i className="material-icons">camera_alt</i>
          </Button>
        </label>
      </div>
    );
  }

  //Render state with image preview
  const renderUploadedState = () => {
    return (
      <div className={formStyles.fileFieldUpload}>
        <CardActionArea>
          <img
            p={100}
            width="100%"
            className={classes.media}
            src={img}
            alt={""}
          />
        </CardActionArea>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
          <Button component="span" variant="outlined" className={formStyles.fileFormButton}>
              <i className="material-icons">camera_alt</i>
          </Button>
        </label>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        {(formState === "ADD" && renderInitialState()) ||
          (formState === "EDIT" &&
            renderUploadedState())}
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(ImageUpload);
