import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { connect } from "react-redux";
import useReduxModule from "../../useReduxModule";
import teamMembersReducer, { INITIAL_STATE } from "./reducers";
import TeamMembersSaga from "./sagas";
import { fetchTeamMembers, setSelectedTeamMember } from "./actions";
import TeamMember from "./TeamMember";
import TeamMemberModal from "./TeamMemberModal";

// module name
const TEAM_MEMBERS_LIST = "TEAM_MEMBERS_LIST";
function TeamMembersList({
  apartment,
  teamMembers,
  selectedTeamMember,
  fetchTeamMembers,
  setSelectedTeamMember,
}) {
  const classes = styles();
  useReduxModule(TEAM_MEMBERS_LIST, teamMembersReducer, TeamMembersSaga);

  useEffect(() => {
    if (apartment) {
      fetchTeamMembers(apartment.apartmentId);
    }
  }, [apartment]);

  const [teamMemberModalVisible, setTeamMemberModalVisible] = useState(false);

  function handleTeamMemberModalToggle() {
    setTeamMemberModalVisible(!teamMemberModalVisible);
  }

  function renderTeamMembers() {
    if (teamMembers) {
      return teamMembers.map((teamMember) => (
        <TeamMember
          teamMember={teamMember}
          teamMemberModalVisible={teamMemberModalVisible}
          setSelectedTeamMember={setSelectedTeamMember}
          handleTeamMemberModalToggle={handleTeamMemberModalToggle}
          key={teamMember.id}
        />
      ));
    }
  }

  function renderTeamMemberModal() {
    return (
      <TeamMemberModal
        teamMember={selectedTeamMember}
        teamMemberModalVisible={teamMemberModalVisible}
        handleTeamMemberModalToggle={handleTeamMemberModalToggle}
      />
    );
  }

  if (teamMembers) {
    if (teamMembers.length > 0) {
      return (
        <div>
          <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
            Meet the Team
          </h3>
          <div
            className={`${classes.flexRow} ${classes.topMarginAboveButtons}`}
          >
            {renderTeamMembers()}
          </div>
          {teamMemberModalVisible && renderTeamMemberModal()}
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  const {
    apartmentState,
    [TEAM_MEMBERS_LIST]: taskState = INITIAL_STATE,
  } = state;
  return {
    apartment: apartmentState.apartment,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTeamMembers: (apartmentId) => dispatch(fetchTeamMembers(apartmentId)),
    setSelectedTeamMember: (teamMember) =>
      dispatch(setSelectedTeamMember(teamMember)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembersList);
