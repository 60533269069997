import { SET_SNACKS, ADD_SNACK, REMOVE_SNACK } from "./actions";

export const INITIAL_STATE = {
  snacks: []
};

export const SnacksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SNACKS:
      return { ...state, snacks: action.snacks };
    case ADD_SNACK:
      return { ...state, snacks: [...state.snacks, action.snack] };
    case REMOVE_SNACK:
      return {
        ...state,
        snacks: state.snacks.filter(snack => snack.id !== action.snackId)
      };
    default: return state;
  }
};

export default SnacksReducer;