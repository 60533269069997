const firebase = require("firebase");
require("firebase/auth");
require("firebase/firestore");
require("firebase/functions");
require("firebase/storage");

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(config);
    this.auth = this.app.auth();
    this.Auth = this.app.auth;
    this.firestore = this.app.firestore();
    this.functions = this.app.functions();
    this.storage = this.app.storage();
  }

  callableTimeout = (fn, data, timeout = 10000) => {
    const callablePromise = new Promise((resolve, reject) => {
      this.functions.httpsCallable(fn)(data).then(resolve).catch(reject);
    })
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(reject, timeout, "timeout");
    });
    return Promise.race([callablePromise, timeoutPromise])
      .catch(error => {
        console.error(error);
        throw error;
      });
  };

  getUserApartmentDocumentRef = (uid) => {
    const moveDoc = this.firestore.collection("moves").doc(uid);
    return moveDoc
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const move = snapshot.data();
          return move.apartmentId;
        } else {
          throw new Error("Could not find move");
        }
      })
      .then((apartmentId) => {
        return this.firestore.collection("apartments").doc(apartmentId);
      });
  };

  /**
   * @return {Promise<Object>} Promise of Apartment Object
   */
  getUserApartment = (uid) => {
    const apartmentDoc = this.getUserApartmentDocumentRef(uid);
    return apartmentDoc
      .then((apartmentRef) => apartmentRef.get())
      .then((snapshot) => {
        if (snapshot.exists) {
          const apartment = snapshot.data();
          return apartment;
        } else {
          throw new Error("Error: Could Not Find User Apartment");
        }
      });
  };

  getUserResidentDocumentRef = (uid) => {
    return this.getUserApartmentDocumentRef(uid).then((apartmentRef) =>
      apartmentRef.collection("residents").doc(uid)
    );
  };

  getUserResident = (uid) => {
    return this.getUserResidentDocumentRef(uid)
      .then((docRef) => docRef.get())
      .then((residentDoc) => residentDoc.data());
  };

  getUserResidentTaskCollection = (uid) => {
    return this.getUserResidentDocumentRef(uid).then((docRef) =>
      docRef.collection("tasks")
    );
  };

  getUserResidentTaskDocumentRef = (uid, taskId) => {
    return this.getUserResidentTaskCollection(uid).then((collectionRef) =>
      collectionRef.doc(taskId)
    );
  };

  getUserResidentTask = (uid, taskId) => {
    return this.getUserResidentTaskDocumentRef(uid, taskId)
      .then((taskRef) => taskRef.get())
      .then((taskDoc) => taskDoc.data());
  };

  getUserApartmentAmenityCollection = (uid) => {
    return this.getUserApartmentDocumentRef(uid).then((docRef) =>
      docRef.collection("amenities")
    );
  };

  getUserAmartmentAmenityDocumentRef = (uid, amenityId) => {
    return this.getUserApartmentAmenityCollection(uid).then((collectionRef) =>
      collectionRef.doc(amenityId)
    );
  };

  getUserApartmentAmenity = (uid, amenityId) => {
    return this.getUserAmartmentAmenityDocumentRef(uid, amenityId)
      .then((amenityRef) => amenityRef.get())
      .then((amenityDoc) => amenityDoc.data());
  };
}

export default Firebase;
