import React from "react";
import { connect } from "react-redux";
import { styles } from "./styles.js";

function ApartmentAbout({ apartment }) {
  const classes = styles();
  if (apartment) {
    return (
      <div>
        <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
          About
        </h3>
        <div
          className={`${classes.leftJustify} ${classes.textPadding}  ${classes.apartmentTextMargins}`}
        >
          {apartment.about}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  const { apartmentState } = state;
  return {
    apartment: apartmentState.apartment,
  };
};

export default connect(mapStateToProps, null)(ApartmentAbout);
