import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  amenities: null,
};

const amenitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_AMENITIES:
      return { ...state, amenities: action.amenities };
    default:
      return state;
  }
};

export default amenitiesReducer;
