import React from "react";
import { connect } from "react-redux";
import { modalStyles } from "./styles";
import { Modal, Paper } from "@material-ui/core";
import { setSelectedTeamMember } from "./actions.js";
import renderClickableText from "../../renderClickableText";
import TeamMemberDetailRow from "./TeamMemberDetailRow";
import backIcon from "./img/arrow_back_ios-24px.svg";
import forwardIcon from "./img/arrow_forward_ios-24px.svg";
import closeIcon from "./img/close-24px.svg";
import teamIcon from "./img/account_circle-24px.svg";

const TeamMemberModal = ({
  teamMembers,
  selectedTeamMember,
  teamMemberModalVisible,
  handleTeamMemberModalToggle,
  setSelectedTeamMember,
}) => {
  const modalClasses = modalStyles();

  function scrollRight() {
    const indexOfSelectedTeamMember = teamMembers.indexOf(selectedTeamMember);
    if (indexOfSelectedTeamMember !== teamMembers.length - 1) {
      setSelectedTeamMember(teamMembers[indexOfSelectedTeamMember + 1]);
    } else {
      setSelectedTeamMember(teamMembers[0]);
    }
  }

  function scrollLeft() {
    const indexOfSelectedTeamMember = teamMembers.indexOf(selectedTeamMember);
    if (indexOfSelectedTeamMember !== 0) {
      setSelectedTeamMember(teamMembers[indexOfSelectedTeamMember - 1]);
    } else {
      setSelectedTeamMember(teamMembers[teamMembers.length - 1]);
    }
  }

  const handleArrowNavigation = (event) => {
    event.preventDefault();
    if (event.keyCode === 39) {
      scrollRight();
    } else if (event.keyCode === 37) {
      scrollLeft();
    }
    document.removeEventListener("keydown", handleArrowNavigation);
  };

  document.addEventListener("keydown", handleArrowNavigation);

  // swipe handling
  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  let xDown = null;
  let yDown = null;

  function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }
    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;
    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        scrollRight();
        /* reset values */
        xDown = null;
        yDown = null;
        document.removeEventListener("touchstart", handleTouchStart);
        document.removeEventListener("touchmove", handleTouchMove);
      } else {
        /* right swipe */
        scrollLeft();
        /* reset values */
        xDown = null;
        yDown = null;
        document.removeEventListener("touchstart", handleTouchStart);
        document.removeEventListener("touchmove", handleTouchMove);
      }
    }
  }

  return (
    <Modal
      open={teamMemberModalVisible}
      onClose={() => {
        handleTeamMemberModalToggle();
      }}
      className={modalClasses.root}
    >
      <Paper className={modalClasses.paper}>
        <div className={modalClasses.scrollable}>
          <h2>{selectedTeamMember.position}</h2>
          <div>
            <div className={modalClasses.iconContainer}>
              <img
                src={
                  selectedTeamMember.imageURL
                    ? selectedTeamMember.imageURL
                    : teamIcon
                }
                alt="team member"
                className={modalClasses.photo}
              />
            </div>
          </div>
          <div className={modalClasses.leftJustify}>
            {selectedTeamMember.name && (
              <TeamMemberDetailRow
                title="Name"
                data={selectedTeamMember.name}
              />
            )}
            {selectedTeamMember.email && (
              <TeamMemberDetailRow
                title="Email"
                data={renderClickableText(selectedTeamMember.email)}
              />
            )}
            {selectedTeamMember.phoneNumber && (
              <TeamMemberDetailRow
                title="Phone"
                data={renderClickableText(selectedTeamMember.phoneNumber)}
              />
            )}
            {selectedTeamMember.about && (
              <TeamMemberDetailRow
                title="About"
                data={selectedTeamMember.about}
              />
            )}
          </div>
        </div>
        <img
          src={forwardIcon}
          className={modalClasses.rightArrow}
          alt="right scroll arrow"
          onClick={() => scrollRight()}
        />
        <img
          src={backIcon}
          className={modalClasses.leftArrow}
          alt="left scroll arrow"
          onClick={() => scrollLeft()}
        />
        <img
          src={closeIcon}
          className={modalClasses.closeButton}
          alt="close button"
          onClick={() => handleTeamMemberModalToggle()}
        />
      </Paper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    teamMembers: state.TEAM_MEMBERS_LIST.teamMembers,
    selectedTeamMember: state.TEAM_MEMBERS_LIST.selectedTeamMember,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTeamMember: (teamMember) =>
      dispatch(setSelectedTeamMember(teamMember)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberModal);
