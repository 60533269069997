const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_AMENITIES = "SET_AMENITIES";
const FETCH_AMENITIES = "FETCH_AMENITIES";

export const types = {
  SET_ERROR_MESSAGE,
  SET_AMENITIES,
  FETCH_AMENITIES,
};

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error: error,
});

export const setAmenities = (amenities) => ({
  type: SET_AMENITIES,
  amenities: amenities,
});

export const fetchAmenities = (apartmentId) => ({
  type: FETCH_AMENITIES,
  apartmentId: apartmentId,
});
