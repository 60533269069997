import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Redirect, withRouter } from "react-router-dom";
import { history } from "../../index";
import columnMediaQuery from "../../columnMediaQuery";
import { styles } from "./styles.js";
import moment from "moment";
import { Button } from "@material-ui/core";
import checkboxIcon from "./img/check_box-24px.svg";
import todoIcon from "./img/error-24px.svg";

const TaskListItem = (props) => {
  const classes = styles();

  const [numberOfColumns, setNumberOfColumns] = useState(
    classes.listItemContainerOneColumn
  );

  function handleNumberOfColumnsChange(updatedValue) {
    setNumberOfColumns(updatedValue);
  }

  columnMediaQuery(
    classes.listItemContainerOneColumn,
    classes.listItemContainerTwoColumns,
    classes.listItemContainerTwoColumns,
    handleNumberOfColumnsChange,
    numberOfColumns
  );

  function calculateCountdown() {
    const dueDate = props.dueDate;
    const countdown = moment({ hours: 0 }).diff(dueDate, "days") * -1;
    return countdown;
  }

  const formattedDate = () => {
    const dueDate = moment(props.dueDate).format("MMMM D, YYYY");
    const todaysDate = moment().format("MMMM D, YYYY");
    if (dueDate === todaysDate) {
      return "Due today!";
    } else if (props.dueDate < moment()) {
      return "Complete as soon as possible";
    } else if (props.completedDate && props.completedDate !== 0) {
      // do not display due date if completed
    } else {
      return `Due in ${calculateCountdown()} days`;
    }
  };

  const renderImage = () => {
    if (props.completedDate && props.completedDate !== 0) {
      return (
        <img
          src={checkboxIcon}
          alt="black completed checkbox icon"
          className={classes.icon}
        />
      );
    } else {
      return (
        <img
          src={todoIcon}
          alt="red todo exclamation point icon"
          className={classes.icon}
        />
      );
    }
  };

  const taskRedirect = () => {
    history.push(`./tasks?${props.id}`);
    return <Redirect to={`./tasks?${props.id}`} />;
  };

  return (
    <div className={numberOfColumns}>
      <Button
        variant="contained"
        className={classes.listItemContainer}
        onClick={() => taskRedirect()}
      >
        <div
          className={`${classes.taskRow} ${classes.textOverflowContainer} ${
            props.completedDate &&
            props.completedDate !== 0 &&
            classes.completedMargins
          }`}
        >
          <div className={classes.iconColumn}>{renderImage()}</div>
          <div>
            <p className={`${classes.textOverflow}`}>{props.title}</p>
            <p className={classes.dueDate}>{formattedDate()} </p>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default compose(connect(null, null), withRouter)(TaskListItem);
