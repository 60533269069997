const SET_SCREEN = "SET_SCREEN";
const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_APARTMENT_PHOTOS = " SET_APARTMENT_PHOTOS";
const SET_SELECTED_PHOTO = "SET_SELECTED_PHOTO";
const FETCH_GALLERY_PHOTOS = "FETCH_GALLERY_PHOTOS";
const SET_LOADING = "SET_LOADING";

export const types = {
  SET_SCREEN,
  SET_ERROR_MESSAGE,
  SET_APARTMENT_PHOTOS,
  SET_SELECTED_PHOTO,
  FETCH_GALLERY_PHOTOS,
  SET_LOADING,
};

export const setScreen = (screen) => ({
  type: SET_SCREEN,
  screen: screen,
});

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error: error,
});

export const setApartmentPhotos = (photos) => ({
  type: SET_APARTMENT_PHOTOS,
  photos: photos,
});

export const setSelectedPhoto = (selectedPhoto) => ({
  type: SET_SELECTED_PHOTO,
  selectedPhoto: selectedPhoto,
});

export const fetchGalleryPhotos = (uid) => ({
  type: FETCH_GALLERY_PHOTOS,
  uid: uid,
});

export const setLoading = (bool) => ({
  type: SET_LOADING,
  isLoading: bool,
});
