import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  root: {
    backgroundColor: "#f8f8ff",
    padding: "10px",
    height: "auto",
    overflowY: "scroll",
    paddingTop: "50px",
    minHeight: "calc(100vh - 70px)",
    width: "100%",
  },
  taskContainer: {
    margin: "10px auto",
    padding: "0px 0px 10px",
    width: "90%",
    maxWidth: "650px",
  },
  taskRowsContainer: {
    margin: "0 auto",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    padding: "10px 10px 0px",
  },
  infoRow: {
    height: "fit-content",
    display: "flex",
  },
  seperator: {
    borderTop: "3px solid #bbb",
  },
  taskForm: {
    width: "calc(100% - 30px)",
    margin: "10px auto",
  }
})

export const TaskInfoRowStyles = makeStyles({
  root: {
    display: "flex",
    position: "relative",
  },
  alignLargeIcon: {
    verticalAlign: "middle",
    float: "left",
    width: "45px",
    lineHeight: "35px",
    textAlign: "center",
    justifyContent: "center",
  },
  alignLargeText: {
    textAlign: "left",
    width: "calc(100% - 45px)",
    lineHeight: "40px",
    justifyContent: "center",
  },
  alignIcon: {
    verticalAlign: "middle",
    float: "left",
    width: "45px",
    height: "100%",
    lineHeight: "24px",
    textAlign: "center",
  },
  alignText: {
    textAlign: "left",
    maxWidth: "calc(100% - (45px + 5px + 30px))",
  },
});
