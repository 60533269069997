import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  faqs: null,
};

const faqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_FAQS:
      return { ...state, faqs: action.faqs };
    default:
      return state;
  }
};

export default faqReducer;
