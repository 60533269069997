import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  phoneNumbers: null,
};

const phoneNumbersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_PHONE_NUMBERS:
      return { ...state, phoneNumbers: action.phoneNumbers };
    default:
      return state;
  }
};

export default phoneNumbersReducer;
