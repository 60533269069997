import { types } from "./actions";

export const INITIAL_STATE = {
  move: null,
  error: "",
  screen: "resident",
  resident: null,
  isLoading: false,
  welcomeVisible: false,
  updateModalVisible: false,
};

const residentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_MOVE:
      return { ...state, move: action.move };
    case types.SET_SCREEN:
      return { ...state, screen: action.screen };
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_RESIDENT:
      return { ...state, resident: action.resident };
    case types.SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    case types.SET_WELCOME_MODAL_VISIBLE:
      return { ...state, welcomeVisible: action.welcomeVisible };
    case types.SET_UPDATE_MODAL_VISIBLE:
      return {
        ...state,
        updateModalVisible: action.updateModalVisible,
      };
    default:
      return state;
  }
};

export default residentReducer;
