import {
  all,
  call,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  // action types
  SUBMIT_TASK_FORM_SUBMISSION,
  FETCH_PREVIOUS_TASK_FORM_SUBMISSION,

  // actions
  setFormLoading,
  setPreviousSubmission,
  setMode,
} from "./actions";

import { firebase } from '../../index'
import { addSnack } from "../snacks/actions";
import { fetchTask } from "../MoveInTaskPage/actions";
import { sendTaskCompleted } from "../MoveInTaskPage/sagas";


function makeid(l) {
  var text = "";
  var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < l; i++) {
    text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  }
  return text;
}

export function* getLatestSubmission({ uid, taskId }) {
  yield put(setFormLoading(true, ""))
  try {
    const taskRef = yield call(
      {
        context: firebase,
        fn: firebase.getUserResidentTaskDocumentRef
      },
      uid,
      taskId
    );

    const submissionQuery = yield call({
      context: taskRef
        .collection("submissions")
        .limit(1)
        .orderBy("timestamp", "desc"),
      fn: taskRef
        .collection("submissions")
        .limit(1)
        .orderBy("timestamp", "desc")
        .get,
    });

    if (submissionQuery.docs[0]) {
      const submission = submissionQuery.docs[0].data();
      yield put(setPreviousSubmission(submission));
      yield put(setMode("lastSubmission"))
    } else { /* no entry */ }
  } catch (error) { console.error(error); }

  yield put(setFormLoading(false, ""))
};

export function* submitTaskForm({ uid, taskId, formSubmission }) {
  yield put(setFormLoading(true, "Submitting Form..."));
  try {
    const apartment = yield call(
      { context: firebase, fn: firebase.getUserApartment },
      uid
    );
    yield setTimeout(() => { }, 10000)
    // upload files in form

    const fileFieldUploads = formSubmission.fields.filter(
      field => field.input === "file" && field.fileURL !== undefined
    )
    const uploads = fileFieldUploads.map(field => {
      // format file
      const metadata = { contentType: 'image/jpeg' };
      const base64Url = "data:text/plain;base64,";
      const dataUrl = base64Url.concat(field.fileURL);
      const randomString = "a" + makeid(24);
      const storageRef = firebase.storage
        .ref(`userFiles/${uid}/${randomString}`);

      // wrap putString call in promise
      return new Promise((resolve, reject) => {
        storageRef
          .putString(dataUrl, 'data_url', metadata)
          .then(result => resolve(result))
          .catch(error => reject(error))
      })
        .then(result => {
          return storageRef.getDownloadURL();
        })
        .then(downloadURL => {
          // update field object to only have value as downloadURL
          field.value = downloadURL;
          delete field.fileURL;
          delete field.data;
          return `Uploaded image to ${storageRef.fullPath}`;
        });
    })
    yield Promise.all(uploads);
    yield call(
      {
        context: firebase,
        fn: firebase.callableTimeout,
      },
      "submitTaskFormForApartmentResident",
      {
        userId: uid,
        apartmentId: apartment.apartmentId,
        taskId,
        formSubmission,
      }
    );
    yield getLatestSubmission({ uid, taskId });
    yield sendTaskCompleted({ uid, taskId, completed: true });
    yield put(addSnack(
      "FormSubmission",
      `Information submitted to ${apartment.name}`,
      "success"
    ));
  } catch (error) { console.error(error); }
  yield put(setFormLoading(false, ""));
}

export function* TaskFormSagas() {
  yield all([
    takeLatest(SUBMIT_TASK_FORM_SUBMISSION, submitTaskForm),
    takeLatest(FETCH_PREVIOUS_TASK_FORM_SUBMISSION, getLatestSubmission),
  ]);
}

export default TaskFormSagas;