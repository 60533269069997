import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  greyBackground: {
    backgroundColor: "lightGrey",
    padding: 10,
    paddingLeft: 15,
    marginBottom: 0,
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  listItemContainerOneColumn: {
    width: "100%",
  },
  listItemContainerTwoColumns: {
    width: "50%",
  },
  listItemContainerThreeColumns: {
    width: "33.3%",
  },
  buttonStyling: {
    margin: 5,
    marginLeft: "5%",
    marginRight: "5%",
    padding: 10,
    paddingTop: 5,
    paddingBottom: 15,
    textAlign: "left",
    width: "90%",
    backgroundColor: "white",
    textTransform: "none",
    fontSize: 18,
    whiteSpace: "nowrap",
  },
  centerText: {
    paddingTop: 7,
  },
  phoneNumber: {
    fontSize: "0.8em",
  },
  centerPhoneNumberText: {
    padding: 0,
    margin: 0,
  },
  phoneNumberRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginBottom: -10,
    overflowY: "hidden",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transparent",
    },
  },
  iconColumn: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
    paddingTop: 3,
  },
  icon: {
    alignSelf: "center",
    height: 32,
    width: 32,
    paddingRight: 5,
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "left",
    justifyContent: "left",
  },
  topMarginAboveButtons: {
    marginTop: 10,
  },
  phoneNumberTitlePadding: {
    ["@media (max-width: 500px)"]: {
      paddingTop: 5,
    },
  },
  toggleDisplay: {
    ["@media (max-width: 500px)"]: {
      display: "none",
    },
  },
});
