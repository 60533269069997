const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
const SET_SELECTED_TEAM_MEMBER = "SET_SELECTED_TEAM_MEMBER";
const FETCH_TEAM_MEMBERS = "FETCH_TEAM_MEMBERS";

export const types = {
  SET_ERROR_MESSAGE,
  SET_TEAM_MEMBERS,
  SET_SELECTED_TEAM_MEMBER,
  FETCH_TEAM_MEMBERS,
};

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const setTeamMembers = (teamMembers) => ({
  type: SET_TEAM_MEMBERS,
  teamMembers,
});

export const setSelectedTeamMember = (selectedTeamMember) => ({
  type: SET_SELECTED_TEAM_MEMBER,
  selectedTeamMember,
});

export const fetchTeamMembers = (apartmentId) => ({
  type: FETCH_TEAM_MEMBERS,
  apartmentId,
});
