export const SET_FORM_LOADING = "SET_FORM_LOADING";
export const SET_FORM_MODE = "SET_FORM_MODE";
export const SET_PREVIOUS_SUBMISSON = "SET_PREVIOUS_SUBMISSION";
export const SUBMIT_TASK_FORM_SUBMISSION = "SUBMIT_TASK_FORM_SUBMISSION";
export const FETCH_PREVIOUS_TASK_FORM_SUBMISSION = "FETCH_PREVIOUS_TASK_FORM_SUBMISSION";

export const setFormLoading = (loading, message) => ({
  type: SET_FORM_LOADING,
  loading, message
});

export const submitTaskFormSubmission = (uid, taskId, formSubmission) => ({
  type: SUBMIT_TASK_FORM_SUBMISSION,
  uid,
  taskId,
  formSubmission,
});

export const setMode = mode => ({
  type: SET_FORM_MODE,
  mode
});

export const setPreviousSubmission = (submission) => ({
  type: SET_PREVIOUS_SUBMISSON,
  submission,
})

export const fetchPreviousTaskFormSubmission = (uid, taskId) => ({
  type: FETCH_PREVIOUS_TASK_FORM_SUBMISSION,
  uid,
  taskId,
})