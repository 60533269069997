// react redux
import React, { useState } from 'react';
import { connect } from 'react-redux';
import useReduxModule from '../../useReduxModule';
import { TaskFormReducer, INITIAL_STATE } from './reducers';
import {
  submitTaskFormSubmission,
  fetchPreviousTaskFormSubmission,
  setMode,
  setFormLoading
} from './actions';

//subcomponents & styles
import {
  Card,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
  CardMedia,
} from '@material-ui/core';
import { TaskFormStyles } from './styles';
import YesNoSwitch from './YesNoSwitch';
import ImageUpload from './ImageUpload'
import TaskFormSagas from './sagas';
import { useEffect } from 'react';
import LoadingView from '../ReusableComponents/LoadingViewSmall';
import TaskFormField from './TaskFormField';

const TASK_FORM = "TASK_FORM"

export const TaskForm = ({
  // parent data
  form,
  className,
  user,
  taskId,

  // state
  prevSubmission,
  loading,
  loadingMessage,
  mode,

  // dipatch
  submitFormSubmission,
  fetchPreviousSubmission,
  setMode,
  setLoading,
}) => {
  const classes = TaskFormStyles();

  useReduxModule(TASK_FORM, TaskFormReducer, TaskFormSagas)

  const [formSubmission, setFormSubmission] = useState({
    ...form,
    fields: form.fields.map(field => ({ ...field, value: field.input === "yesNo" ? "NO" : "" }))
  });

  useEffect(() => {
    if (user && taskId) {
      fetchPreviousSubmission(user.uid, taskId)
    }
  }, [user, taskId])

  useEffect(() => {
    if (prevSubmission) {
      setFormSubmission({
        ...form,
        fields: form.fields.map(field => ({
          ...field,
          value: prevSubmission.fields
            .find(prevField => prevField.id === field.id).value,
        })),
      });
    }
  }, [prevSubmission])

  const updateFormField = (index, value) => {
    var fields = formSubmission.fields;
    fields[index].value = value;
    setFormSubmission({ ...form, fields: fields });
  }

  const updateFileField = (index, fileURL) => {
    var fields = formSubmission.fields;
    fields[index].fileURL = fileURL
    setFormSubmission({ ...form, fields: fields });
  }

  const updateFileFieldPreview = (index, data) => {
    var fields = formSubmission.fields;
    fields[index].data = data
    setFormSubmission({ ...form, fields: fields });
  }

  const EditFormField = (field, index) => {
    switch (field.input) {
      case "text":
        return <TextField
          key={field.id}
          id={field.id}
          variant="outlined"
          className={classes.formField}
          label={field.prompt}
          value={field.value}
          onChange={e => { updateFormField(index, e.target.value) }}
        />
      case "phonenumber":
        return <TextField
          key={field.id}
          id={field.id}
          variant="outlined"
          className={classes.formField}
          InputProps={{ type: "tel" }}
          label={field.prompt}
          value={field.value}
          onChange={e => { updateFormField(index, e.target.value) }}
        />
      case "yesNo":
        return <div key={field.id} className={classes.yesNoField}>
          <Typography
            className={classes.yesNoFieldPrompt}
            variant="subtitle1"
          >
            {field.prompt}
          </Typography>
          <div className={classes.yesNoFieldSwitch}>
            <YesNoSwitch
              id={field.id}
              status={field.value === "YES"}
              onChange={e => {
                updateFormField(index, e.target.checked ? "YES" : "NO")
              }}
            />
          </div>
        </div>
      case "file":
        return <div key={field.id} className={classes.fileField}>
          <Typography
            className={classes.fileFormPrompt}
            variant="subtitle1"
          >
            {field.prompt}
          </Typography>
          <ImageUpload
            className={classes.fileFieldUpload}
            formState={field.fileURL || field.value ? "EDIT" : "ADD"}
            handleUploadImg={file => { updateFileField(index, file) }}
            handleImgPreview={data => { updateFileFieldPreview(index, data) }}
            img={field.data ? field.data : field.value}
          />
        </div>
      case "none":
        return <Typography
          className={classes.fileFormPrompt}
          variant="subtitle1"
        >
          {field.prompt}
        </Typography>
      default: return ""
    }
  }

  const openEdit = () => {
    setFormSubmission({
      fields: form.fields.map(field => ({
        ...field,
        value: prevSubmission
          .fields
          .find(prev => field.id === prev.id).value ?? "",
      }))
    });
    setMode("edit");
  }

  const renderSubmission = (submission) => (
    <div className={classes.submissionWrapper}>
      <div className={classes.submissionFieldsWrapper} >
        {submission.fields.map((field, index) =>
          <TaskFormField classes={classes} field={field} index={index} key={field.id} />
        )}
      </div>
      <Button
        className={classes.editButton}
        onClick={openEdit}
        variant="contained"
      >
        EDIT
      </Button>
    </div>
  );

  const submitForm = () =>
    submitFormSubmission(user.uid, taskId, formSubmission);

  if (loading) {
    return <LoadingView
      className={className}
      message={loadingMessage}
    />
  }

  switch (mode) {
    case "new":
      return <div className={className}>
        <Card className={classes.root} elevation={0} >
          {
            formSubmission.fields.map(EditFormField)
          }
          <Button
            onClick={submitForm}
            className={classes.submitButton}
            variant="contained"
          >
            SUBMIT
      </Button>
        </Card>
      </div>
    case "edit":
      return <div className={className} >
        <Card className={classes.root} elevation={0} >
          {
            formSubmission.fields.map(EditFormField)
          }
          <div className={classes.submitEditWrapper}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={() => { setMode("lastSubmission") }}
            >
              CANCEL
        </Button>
            <Button
              onClick={submitForm}
              className={classes.submitEditButton}
              variant="contained"
            >
              SUBMIT
        </Button>
          </div>
        </Card>
      </div>
    case "lastSubmission":
      return <div className={className}>
        {renderSubmission(prevSubmission)}
      </div>
    default: break;
  }

  return (
    <LoadingView
      className={className}
      message={loadingMessage}
    />
  );
}

const mapStateToProps = state => {
  const { [TASK_FORM]: taskFormState = INITIAL_STATE } = state;
  return { ...taskFormState }
}

const mapDispatchToProps = dispatch => ({
  submitFormSubmission: (uid, taskId, formSubmission) =>
    dispatch(submitTaskFormSubmission(uid, taskId, formSubmission)),
  fetchPreviousSubmission: (uid, taskId) =>
    dispatch(fetchPreviousTaskFormSubmission(uid, taskId)),
  setMode: mode => dispatch(setMode(mode)),
  setLoading: loading => dispatch(setFormLoading(loading))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);