import React from "react";
import { connect } from "react-redux";
import { styles } from "./styles.js";
import { INITIAL_STATE } from "./reducers";
import ResidentInfoRow from "./ResidentInfoRow";
import mailingIcon from "./img/home-24px.svg";
import unitIcon from "./img/location_city-24px.svg";
import mailboxIcon from "./img/mail-24px.svg";
import parkingIcon from "./img/directions_car-24px.svg";
import additionalInfoIcon from "./img/info-24px.svg";

function ResidentDetails({ resident }) {
  const classes = styles();

  function displayStreetAddress() {
    return (
      <ResidentInfoRow
        type="address"
        infoLabel="Mailing Address: "
        infoText={resident.mailingAddress}
        infoIcon={mailingIcon}
        iconAlt="mailing address home icon"
      />
    );
  }

  function displayUnit() {
    return (
      <ResidentInfoRow
        type="unit"
        infoLabel="Unit: "
        infoText={resident.unit}
        infoIcon={unitIcon}
        iconAlt="apartment unit building icon"
      />
    );
  }

  function displayMailbox() {
    return (
      <ResidentInfoRow
        type="mailbox"
        infoLabel="Mailbox: "
        infoText={resident.mailbox}
        infoIcon={mailboxIcon}
        iconAlt="mail icon"
      />
    );
  }

  function displayParkingSpace() {
    return (
      <ResidentInfoRow
        type="parkingSpace"
        infoLabel="Parking Space: "
        infoText={resident.parkingSpace}
        infoIcon={parkingIcon}
        iconAlt="parking car icon"
      />
    );
  }

  function displayAdditionalInfo() {
    return (
      <ResidentInfoRow
        type="additionalInfo"
        infoLabel="Additional Info: "
        infoText={resident.additionalInfo}
        infoIcon={additionalInfoIcon}
        iconAlt="additional info i icon"
      />
    );
  }

  if (
    resident &&
    (resident.mailingAddress ||
      resident.unit ||
      resident.mailbox ||
      resident.parkingSpace ||
      resident.additionalInfo)
  ) {
    return (
      <div>
        <h3 className={`${classes.leftJustify} ${classes.greyBackground}`}>
          Resident Info
        </h3>
        <div className={classes.leftJustify}>
          {resident.mailingAddress && displayStreetAddress()}
          {resident.unit && displayUnit()}
          {resident.mailbox && displayMailbox()}
          {resident.parkingSpace && displayParkingSpace()}
          {resident.additionalInfo && displayAdditionalInfo()}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  const { RESIDENT_PAGE = INITIAL_STATE } = state;
  return {
    resident: RESIDENT_PAGE.resident,
  };
};

export default connect(mapStateToProps, null)(ResidentDetails);
