import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import {
  styles,
  textFieldInputStyles,
  buttonStyles,
  errorStyles,
} from "./styles.js";
import {
  setScreen,
  setErrorMessage,
  setInviteCode,
  signUpUser,
  fetchUrls,
} from "./actions";
import "firebase/auth";

function SignUpForm({
  inviteCode,
  goToLogin,
  user,
  error,
  termsUrl,
  privacyPolicyUrl,
  clearError,
  clearInviteCode,
  signUpUser,
  fetchUrls,
}) {
  const classes = styles()();
  const textFieldClasses = textFieldInputStyles()();
  const buttonClasses = buttonStyles()();
  const errorClasses = errorStyles()();

  const signUpUserWithInviteCode = (event) => {
    event.preventDefault();
    signUpUser(
      event.target.email.value,
      event.target.password.value,
      event.target.confirmPassword.value,
      user,
      inviteCode.code
    );
  };

  fetchUrls();

  function displayErrorMessage() {
    if (error === "emailInUse") {
      return "That email is already in use! Try logging in.";
    } else if (error === "passwordLength") {
      return "Your password must be at least 6 characters.";
    } else if (error === "passwordMatch") {
      return "These passwords do not match!";
    } else {
      return "Something went wrong.";
    }
  }

  function toggleErrorVisibility() {
    if (error) {
      return errorClasses.errorText;
    } else {
      return `${errorClasses.errorText} ${errorClasses.errorTextHidden}`;
    }
  }

  function toggleErrorTextStylingEmail() {
    if (error === "emailInUse") {
      return `${textFieldClasses.errorformTextField} ${textFieldClasses.formTextField}`;
    } else {
      return textFieldClasses.formTextField;
    }
  }

  function toggleErrorTextStylingPassword() {
    if (error === "passwordLength" || error === "passwordMatch") {
      return `${textFieldClasses.errorformTextField} ${textFieldClasses.formTextField}`;
    } else {
      return textFieldClasses.formTextField;
    }
  }

  const [firstName, setFirstName] = useState(inviteCode.firstName);
  const [lastName, setLastName] = useState(inviteCode.lastName);
  const [email, setEmail] = useState(inviteCode.email);

  const onFirstNameChange = (event) => setFirstName(event.target.value);
  const onLastNameChange = (event) => setLastName(event.target.value);
  const onEmailChange = (event) => setEmail(event.target.value);

  return (
    <div className={classes.signUpWrapper}>
      <h3 className={classes.createAccount}> Create Account </h3>
      <form onSubmit={signUpUserWithInviteCode}>
        <TextField
          id="firstName"
          label="First Name"
          value={firstName}
          onChange={onFirstNameChange}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={textFieldClasses.formTextField}
        />
        <TextField
          id="lastName"
          label="Last Name"
          value={lastName}
          onChange={onLastNameChange}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={textFieldClasses.formTextField}
        />
        <TextField
          id="email"
          label="Email Address"
          value={email}
          onChange={onEmailChange}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={toggleErrorTextStylingEmail()}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={toggleErrorTextStylingPassword()}
        />
        <TextField
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={toggleErrorTextStylingPassword()}
        />
        <p className={toggleErrorVisibility()}>Oops! {displayErrorMessage()}</p>
        <p className={classes.policyText}>
          By continuing, I agree to the{" "}
          <a
            href={termsUrl}
            className={classes.urlLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>{" "}
          and{" "}
          <a
            href={privacyPolicyUrl}
            className={classes.urlLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          .
        </p>
        <Button
          className={`${buttonClasses.button} ${buttonClasses.submitButton}`}
          onClick={() => {
            clearError();
          }}
          type="submit"
        >
          Sign Up
        </Button>
      </form>
      <Button
        className={`${buttonClasses.button}`}
        variant="outlined"
        onClick={() => {
          goToLogin();
          clearError();
          clearInviteCode();
        }}
      >
        Back
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    error: state.LOGIN_PAGE.error,
    inviteCode: state.LOGIN_PAGE.inviteCode,
    user: state.userState.user,
    termsUrl: state.LOGIN_PAGE.termsUrl,
    privacyPolicyUrl: state.LOGIN_PAGE.privacyPolicyUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToLogin: () => dispatch(setScreen("login")),
    clearError: () => dispatch(setErrorMessage("")),
    clearInviteCode: () => dispatch(setInviteCode(null)),
    signUpUser: (email, password, confirmPassword, user, inviteCode) =>
      dispatch(signUpUser(email, password, confirmPassword, user, inviteCode)),
    fetchUrls: () => dispatch(fetchUrls()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
