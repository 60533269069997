import React, { useState } from "react";
import columnMediaQuery from "../../columnMediaQuery";
import { styles } from "./styles.js";
import { Button } from "@material-ui/core";
import phoneIcon from "./img/phone-24px.svg";

const PhoneNumber = (props) => {
  const classes = styles();

  const [numberOfColumns, setNumberOfColumns] = useState(
    classes.listItemContainerOneColumn
  );

  function handleNumberOfColumnsChange(updatedValue) {
    setNumberOfColumns(updatedValue);
  }

  columnMediaQuery(
    classes.listItemContainerOneColumn,
    classes.listItemContainerTwoColumns,
    classes.listItemContainerThreeColumns,
    handleNumberOfColumnsChange,
    numberOfColumns
  );

  return (
    <div className={numberOfColumns}>
      <a
        href={`tel: ${props.phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <Button variant="contained" className={classes.buttonStyling}>
          <div className={classes.phoneNumberRow}>
            <div className={classes.iconColumn}>
              <img
                src={phoneIcon}
                alt="black phone icon"
                className={classes.icon}
              />
            </div>
            <div className={classes.centerPhoneNumberText}>
              <div className={classes.phoneNumberTitlePadding}>
                {props.title}
              </div>
              <div
                className={`${classes.toggleDisplay} ${classes.phoneNumber}`}
              >
                {props.phoneNumber}
              </div>
            </div>
          </div>
        </Button>
      </a>
    </div>
  );
};

export default PhoneNumber;
