const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_PHONE_NUMBERS = "SET_PHONE_NUMBERS";
const FETCH_PHONE_NUMBERS = "FETCH_PHONE_NUMBERS";

export const types = {
  SET_ERROR_MESSAGE,
  SET_PHONE_NUMBERS,
  FETCH_PHONE_NUMBERS,
};

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error: error,
});

export const setPhoneNumbers = (phoneNumbers) => ({
  type: SET_PHONE_NUMBERS,
  phoneNumbers: phoneNumbers,
});

export const fetchPhoneNumbers = (apartmentId) => ({
  type: FETCH_PHONE_NUMBERS,
  apartmentId: apartmentId,
});
