import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Redirect, withRouter } from "react-router-dom";
import { history } from "../../index";
import columnMediaQuery from "../../columnMediaQuery";
import { styles } from "./styles.js";
import { Button } from "@material-ui/core";
import buildingIcon from "./img/apartment-24px.svg";

const Amenity = (props) => {
  const classes = styles();

  const [numberOfColumns, setNumberOfColumns] = useState(
    classes.listItemContainerOneColumn
  );

  function handleNumberOfColumnsChange(updatedValue) {
    setNumberOfColumns(updatedValue);
  }

  columnMediaQuery(
    classes.listItemContainerOneColumn,
    classes.listItemContainerTwoColumns,
    classes.listItemContainerThreeColumns,
    handleNumberOfColumnsChange,
    numberOfColumns
  );

  function amenityRedirect() {
    history.push(`./amenities?${props.amenity.id}`);
    return <Redirect to={`./amenities?${props.amenity.id}`} />;
  }

  return (
    <div className={numberOfColumns}>
      <Button
        variant="contained"
        className={classes.buttonStyling}
        onClick={() => amenityRedirect()}
      >
        <div className={classes.amenityRow}>
          <div className={classes.iconColumn}>
            <img
              src={buildingIcon}
              alt="black building icon"
              className={classes.icon}
            />
          </div>
          <div className={classes.centerText}>
            <div>{props.amenity.name}</div>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default compose(connect(null, null), withRouter)(Amenity);
