import React, { useEffect } from "react";
import { connect } from "react-redux";
import { INITIAL_STATE } from "./reducers";
import { setMoveInTasksCompleteModalVisible } from "./actions";
import { modalStyles } from "./styles";
import { Modal, Paper, Button } from "@material-ui/core";

const MoveInTasksCompleteModal = ({
  apartment,
  moveInTasksCompleteModalVisible,
  setMoveInTasksCompleteModalVisible,
}) => {
  const modalClasses = modalStyles();
  useEffect(() => {}, [apartment]);

  if (apartment) {
    return (
      <Modal
        open={moveInTasksCompleteModalVisible}
        onClose={() => {
          setMoveInTasksCompleteModalVisible(false);
        }}
        className={modalClasses.root}
      >
        <Paper className={modalClasses.paper}>
          <h2>Amazing job!!!</h2>
          <div className={modalClasses.bodyText}>
            You completed all the Required Move-in Tasks! You’re all set to move
            into {apartment.apartmentName} now!
          </div>
          <Button
            onClick={() => {
              setMoveInTasksCompleteModalVisible(false);
            }}
            className={modalClasses.button}
          >
            THANKS
          </Button>
        </Paper>
      </Modal>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  const { apartmentState, MOVE_IN_TASKS_LIST = INITIAL_STATE } = state;
  return {
    apartment: apartmentState.apartment,
    moveInTasksCompleteModalVisible:
      MOVE_IN_TASKS_LIST.moveInTasksCompleteModalVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMoveInTasksCompleteModalVisible: (moveInTasksCompleteModalVisible) =>
      dispatch(
        setMoveInTasksCompleteModalVisible(moveInTasksCompleteModalVisible)
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveInTasksCompleteModal);
