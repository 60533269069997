import {
  SET_TASK,
} from "./actions";

export const INITIAL_STATE = {
  taskId: "",
  title: "",
  description: undefined,
  dueDate: undefined,
  completedDate: undefined,
  form: undefined,
  prevSubmission: undefined,
  loadingForm: false,
  loadingFormMessage: "",
};

export const ResidentTaskPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TASK:
      return {
        ...state,
        taskId: action.task.id,
        title: action.task.title,
        description: action.task.description,
        dueDate: action.task.dueDate,
        completedDate: action.task.completedDate,
        form: action.task.form,
      };
    default: return state;
  }
}

export default ResidentTaskPageReducer;