export default function columnMediaQuery(
  oneColumnClass,
  twoColumnClass,
  threeColumnClass,
  stateFunction,
  stateVariable
) {
  function columnListener() {
    if (
      window.matchMedia("(max-width: 695px)").matches &&
      stateVariable !== oneColumnClass
    ) {
      stateFunction(oneColumnClass);
    } else if (
      window.matchMedia("(max-width: 1000px) and (min-width: 700px)").matches &&
      stateVariable !== twoColumnClass
    ) {
      stateFunction(twoColumnClass);
    } else if (
      window.matchMedia("(min-width: 1005px)").matches &&
      stateVariable !== threeColumnClass
    ) {
      stateFunction(threeColumnClass);
    }
  }

  const oneColumnMediaQuery = window.matchMedia("(max-width: 695px)");
  const twoColumnMediaQuery = window.matchMedia(
    "(max-width: 1000px) and (min-width: 700px)"
  );
  const threeColumnMediaQuery = window.matchMedia("(min-width: 1005px)");
  oneColumnMediaQuery.addListener(columnListener);
  twoColumnMediaQuery.addListener(columnListener);
  threeColumnMediaQuery.addListener(columnListener);
  columnListener();
}
