import { Typography, Box } from "@material-ui/core"
import React from "react"

export const TaskFormField = ({ field, index, classes }) => {
  switch (field.input) {
    case "file":
      return <Typography
        variant="subtitle1"
        key={field.id}
        className={classes.submissionFieldWrapper}
        align="inherit"
      >
        <Box className={classes.submissionFieldPrompt} fontWeight="fontWeightBold">
          {field.prompt}
        </Box>
        {field.value && field.value !== ""
          ? <img
            className={classes.submissionFieldImageValue}
            src={field.value}
          />
          : 
          <Box className={classes.submissionFieldTextValue} fontWeight="fontWeightRegular">
            NONE
          </Box>
        }
      </Typography>
    default:
      return <Typography
        variant="subtitle1"
        key={field.id}
        className={classes.submissionFieldWrapper}
      >
        <Box className={classes.submissionFieldPrompt} fontWeight="fontWeightBold">
          {field.prompt}
        </Box>
        <Box className={classes.submissionFieldTextValue} fontWeight="fontWeightRegular">
          {field.value}
        </Box>
      </Typography>
  }
};

export default TaskFormField;