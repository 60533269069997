const SET_MOVE = "SET_MOVE";
const SET_SCREEN = "SET_SCREEN";
const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SET_RESIDENT = "SET_RESIDENT";
const FETCH_MOVE = "FETCH_MOVE";
const FETCH_APARTMENT = "FETCH_APARTMENT";
const FETCH_RESIDENT = "FETCH_RESIDENT";
const SET_LOADING = "SET_LOADING";
const SET_WELCOME_MODAL_VISIBLE = "SET_WELCOME_MODAL_VISIBLE";
const SET_UPDATE_MODAL_VISIBLE = "SET_UPDATE_MODAL_VISIBLE";

export const types = {
  SET_MOVE,
  SET_SCREEN,
  SET_ERROR_MESSAGE,
  SET_RESIDENT,
  FETCH_MOVE,
  FETCH_APARTMENT,
  FETCH_RESIDENT,
  SET_LOADING,
  SET_WELCOME_MODAL_VISIBLE,
  SET_UPDATE_MODAL_VISIBLE,
};

export const setMove = (move) => ({
  type: SET_MOVE,
  move,
});

export const setScreen = (screen) => ({
  type: SET_SCREEN,
  screen,
});

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const setResident = (resident) => ({
  type: SET_RESIDENT,
  resident,
});

export const fetchMove = (uid) => ({
  type: FETCH_MOVE,
  uid,
});

export const fetchApartment = (apartmentId) => ({
  type: FETCH_APARTMENT,
  apartmentId,
});

export const fetchResident = (uid, apartmentId) => ({
  type: FETCH_RESIDENT,
  uid,
  apartmentId,
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  isLoading,
});

export const setWelcomeVisible = (welcomeVisible) => ({
  type: SET_WELCOME_MODAL_VISIBLE,
  welcomeVisible,
});

export const setUpdateModalVisible = (updateModalVisible) => ({
  type: SET_UPDATE_MODAL_VISIBLE,
  updateModalVisible,
});
