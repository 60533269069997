import { types } from "./apartmentActions";
const INITIAL_STATE = {
  apartment: null,
};

const apartmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_APARTMENT: return { ...state, apartment: action.apartment };
    default: return state;
  }
};

export default apartmentReducer;
