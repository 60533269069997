import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles({
  center: {
    marginTop: 10,
    height: "auto",
    width: "90%",
    maxWidth: "650px",
  },
  amenityImage: {
    width: "100%",
    ["@media (min-width: 500px)"]: {
      width: 500,
    },
  },
  bold: {
    fontWeight: "bold",
  },
  leftJustify: {
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "left",
  },
  indentText: {
    paddingLeft: 25,
  },
  navBarPadding: {
    paddingTop: 50,
  },
});
