import React, { useState } from "react";
import { connect } from "react-redux";
import columnMediaQuery from "../../columnMediaQuery";
import { styles } from "./styles.js";
import { setScreen, setSelectedPhoto } from "./actions";
import { GridListTile, GridListTileBar } from "@material-ui/core";

const ApartmentGalleryPhoto = (props) => {
  const classes = styles();
  const [numberOfColumns, setNumberOfColumns] = useState(
    classes.listItemContainerOneColumn
  );

  function handleNumberOfColumnsChange(updatedValue) {
    setNumberOfColumns(updatedValue);
  }

  columnMediaQuery(
    classes.listItemContainerOneColumn,
    classes.listItemContainerTwoColumns,
    classes.listItemContainerThreeColumns,
    handleNumberOfColumnsChange,
    numberOfColumns
  );

  return (
    <GridListTile
      className={numberOfColumns}
      onClick={() => {
        props.setSelectedPhoto(props.photo);
        props.goToSelectedPhoto();
        props.handlePhotoDetailsToggle();
      }}
    >
      <img src={props.url} alt="apartment gallery" />
      <GridListTileBar className={classes.caption} title={props.caption} />
    </GridListTile>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedPhoto: (photo) => dispatch(setSelectedPhoto(photo)),
    goToSelectedPhoto: () => dispatch(setScreen("selectedPhoto")),
  };
};

export default connect(null, mapDispatchToProps)(ApartmentGalleryPhoto);
