import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useReduxModule from "../../useReduxModule";
import ResidentTaskPageReducer, { INITIAL_STATE } from "./reducers";
import { fetchTask, sendTaskCompleted } from "./actions";
import ResidentTaskPageSaga from "./sagas";
import { styles } from "./styles";
import renderClickableText from "../../renderClickableText";
import TaskInfoRow from "./TaskInfoRow";
import TaskForm from "../TaskForm/TaskForm";

// module name
const MOVE_IN_TASK_PAGE = "MOVE_IN_TASK_PAGE";

const FilledButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "#36ba8c",
    margin: "15px",
    "&:hover": {
      backgroundColor: "#aee3d1",
    },
  },
})(Button);

const OutlinedButton = withStyles({
  root: {
    color: "#36ba8c",
    backgroundColor: "white",
    margin: "15px",
    "&:hover": {
      backgroundColor: "#aee3d1",
    },
  },
})(Button);

export const MoveInTaskPage = ({
  // url
  location,

  // dispatch
  fetchTask,
  sendTaskCompleted,

  // data
  user,
  taskId,
  title,
  description,
  dueDate,
  completedDate,
  form,
  loadingForm,
  loadingFormMessage,
}) => {
  useReduxModule(
    MOVE_IN_TASK_PAGE,
    ResidentTaskPageReducer,
    ResidentTaskPageSaga
  );
  useEffect(() => {
    // fetch task from url
    if (user) {
      const taskId = location.search.split("?")[1];
      fetchTask(user.uid, taskId);
    }
  }, [user, fetchTask, location.search]);

  const dateString = () => {
    const date = moment(completedDate ? completedDate : dueDate);
    const prefix = completedDate ? "Completed on " : "Due ";
    return prefix + date.format("dddd, MMMM D, YYYY");
  };

  const renderCompleteButton = () =>
    completed ? (
      <OutlinedButton
        variant={completed ? "outlined" : "contained"}
        onClick={() => {
          sendTaskCompleted(user.uid, taskId, !completed);
        }}
        size="large"
      >
        {completed ? "COMPLETED" : "MARK COMPLETE"}
      </OutlinedButton>
    ) : (
        <FilledButton
          variant={completed ? "outlined" : "contained"}
          onClick={() => {
            sendTaskCompleted(user.uid, taskId, !completed);
          }}
          size="large"
        >
          {completed ? "COMPLETED" : "MARK COMPLETE"}
        </FilledButton>
      );

  const classes = styles();
  const completed = completedDate && completedDate > 0;

  if (!taskId) {
    return <div></div>;
  }
  return (
    <div className={classes.root}>
      <Card className={classes.taskContainer} elevation={4}>
        <div className={classes.taskRowsContainer}>
          <TaskInfoRow
            className={classes.infoRow}
            label={title}
            icon={completed ? "check_box" : "error"}
            font="h6"
          />
          <TaskInfoRow
            className={classes.infoRow}
            label={dateString()}
            icon={"date_range"}
            font="subtitle1"
          />
          <TaskInfoRow
            className={classes.infoRow}
            label={renderClickableText(description)}
            icon={"info_outline"}
            font="subtitle1"
          />
        </div>
        {form
          ? (
            <TaskForm
              className={classes.taskForm}
              form={form}
              user={user}
              taskId={taskId}
            />
          )
          : (renderCompleteButton())
        }
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { [MOVE_IN_TASK_PAGE]: taskState = INITIAL_STATE } = state;
  return {
    user: state.userState.user,
    ...taskState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTask: (uid, taskId) => dispatch(fetchTask(uid, taskId)),
  sendTaskCompleted: (uid, taskId, completed) =>
    dispatch(sendTaskCompleted(uid, taskId, completed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveInTaskPage);
