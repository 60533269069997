import { types } from "./actions";

export const INITIAL_STATE = {
  move: null,
  error: "",
  screen: "resident",
  label: "",
};

const navBarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SCREEN:
      return { ...state, screen: action.screen };
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_LABEL:
      return { ...state, label: action.label };
    default:
      return state;
  }
};

export default navBarReducer;
