import React, { useState } from "react";
import { styles } from "./styles.js";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import renderClickableText from "../../renderClickableText";
import dropdownArrow from "./img/arrow_drop_down-24px.svg";

const FAQListItem = (props) => {
  const classes = styles();

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        square
        expanded={expanded === "panel"}
        onChange={handleChange("panel")}
        className={classes.accordionMain}
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
          className={classes.accordionTitle}
        >
          <Typography className={classes.question}>{props.question}</Typography>
          <img
            src={dropdownArrow}
            alt="drop down arrow"
            className={`${classes.downArrow} ${expanded && classes.flipArrow}`}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.leftJustify}>
            {renderClickableText(props.answer)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FAQListItem;
