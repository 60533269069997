import React from "react";
import { connect } from "react-redux";
import { modalStyles } from "./styles.js";
import { Modal, Paper } from "@material-ui/core";
import { setSelectedPhoto } from "./actions.js";
import backIcon from "./img/arrow_back_ios-24px.svg";
import forwardIcon from "./img/arrow_forward_ios-24px.svg";
import closeIcon from "./img/close-24px.svg";

const PhotoDetailsModal = ({
  photos,
  selectedPhoto,
  photoDetailsVisible,
  handlePhotoDetailsToggle,
  setSelectedPhoto,
}) => {
  const modalClasses = modalStyles();

  function scrollRight() {
    const indexOfSelectedPhoto = photos.indexOf(selectedPhoto);
    if (indexOfSelectedPhoto !== photos.length - 1) {
      setSelectedPhoto(photos[indexOfSelectedPhoto + 1]);
    } else {
      setSelectedPhoto(photos[0]);
    }
  }

  function scrollLeft() {
    const indexOfSelectedPhoto = photos.indexOf(selectedPhoto);
    if (indexOfSelectedPhoto !== 0) {
      setSelectedPhoto(photos[indexOfSelectedPhoto - 1]);
    } else {
      setSelectedPhoto(photos[photos.length - 1]);
    }
  }

  const handleArrowNavigation = (event) => {
    event.preventDefault();
    if (event.keyCode === 39) {
      scrollRight();
    } else if (event.keyCode === 37) {
      scrollLeft();
    }
    document.removeEventListener("keydown", handleArrowNavigation);
  };

  document.addEventListener("keydown", handleArrowNavigation);

  // swipe handling

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  let xDown = null;
  let yDown = null;

  function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }
    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;
    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        scrollRight();
      } else {
        /* right swipe */
        scrollLeft();
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
    document.removeEventListener("touchstart", handleTouchStart);
    document.removeEventListener("touchmove", handleTouchMove);
  }

  return (
    <Modal
      open={photoDetailsVisible}
      onClose={() => {
        handlePhotoDetailsToggle();
      }}
      className={modalClasses.root}
    >
      <Paper className={modalClasses.paper}>
        <img
          src={selectedPhoto.url}
          className={modalClasses.image}
          alt="apartment gallery"
        />

        <div className={modalClasses.caption}>
          <p>{selectedPhoto.caption}</p>
        </div>
        <img
          src={forwardIcon}
          className={modalClasses.rightArrow}
          alt="right scroll arrow"
          onClick={() => scrollRight()}
        />
        <img
          src={backIcon}
          className={modalClasses.leftArrow}
          alt="left scroll arrow"
          onClick={() => scrollLeft()}
        />
        <img
          src={closeIcon}
          className={modalClasses.closeButton}
          alt="close button"
          onClick={() => handlePhotoDetailsToggle()}
        />
      </Paper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    photos: state.APARTMENT_GALLERY.photos,
    selectedPhoto: state.APARTMENT_GALLERY.selectedPhoto,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedPhoto: (photo) => dispatch(setSelectedPhoto(photo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhotoDetailsModal);
