import { makeStyles } from "@material-ui/core/styles";

export const navBarStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    backgroundColor: "#36ba8c",
    position: "fixed",
    height: 50,
  },
  toolBar: {
    height: 50,
  },
  rightJustify: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    verticalAlign: "middle",
  },
  leftJustify: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    verticalAlign: "middle",
  },
  backButton: {
    zIndex: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  centerHeaderText: {
    position: "absolute",
    left: 0,
    width: "100%",
  },
  alignImage: {
    verticalAlign: "middle",
    paddingRight: 5,
    zIndex: 2,
  },
  icon: {
    width: 12,
    paddingLeft: 5,
  },
  bottomPadding: {
    paddingBottom: 13,
  },
  toggleDisplay: {
    ["@media (max-width: 700px)"]: {
      display: "none",
    },
  },
  hidden: {
    display: "none",
  },
});
