import React, { useEffect, useRef } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import NavBar from "./modules/NavBar/";
import LoginPage from "./modules/LoginPage/";
import ResidentPage from "./modules/ResidentPage/";
import ApartmentGallery from "./modules/ApartmentGallery/";
import MoveInTaskPage from "./modules/MoveInTaskPage";
import AmenityPage from "./modules/AmenityPage";
import LoadingScreen from "./modules/LoadingScreen";
import { connect } from "react-redux";
import { firebase, history } from "./index.js";
import { setUser } from "./store/staticReducers/userActions";
import Snacks from "./modules/snacks";

function App({ user, setUser }) {
  let userListener = useRef(0);

  useEffect(() => {
    // set up auth listener
    userListener.current = firebase.auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      if (authUser) {
        // user exist
        if (authUser.isAnonymous) {
          // anonymous user, redirect to login if not already there
          if (
            history.location.pathname !== "/" &&
            history.location.pathname !== "/login"
          ) {
            history.push("/");
          }
        }
      } else {
        // no user object created
        firebase.auth.signInAnonymously();
      }
    });
    return () => userListener.current();
  }, [setUser]);

  return (
    <div className="App">
      <NavBar user={user} />
      <Snacks />
      {user ? (
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/resident" component={ResidentPage} />
          <Route exact path="/gallery" component={ApartmentGallery} />
          <Route path="/tasks" component={MoveInTaskPage} />
          <Route path="/amenities" component={AmenityPage} />
        </Switch>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
