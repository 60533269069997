import { makeStyles } from "@material-ui/core/styles";

export const TaskFormStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0",
  },
  formField: {
    margin: "5px 10px",
    "& label.Mui-focused": {
      color: "gray",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#36ba8c",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#36ba8c",
      },
      "&:hover fieldset": {
        borderColor: "#36ba8c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#36ba8c",
      },
    },
  },
  yesNoField: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 10px"
  },
  yesNoFieldPrompt: {
    width: "75%",
    height: "100%",
    textAlign: "left",
  },
  yesNoFieldSwitch: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  fileField: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 10px",
    alignItems: "center",
  },
  fileFormPrompt: {
    lineHeight: "30px",
    width: "100%",
    textAlign: "left",
  },
  fileFieldUpload: {
    width: "100%",
  },
  fileFormButton: {
    color: "#36ba8c",
    height: "35px",
    width: "100%",
    marginTop: "10px",
    "& material-icons": {
      fontSize: "30px",
    },
  },
  submitEditWrapper: {
    display: "flex",
    margin: "5px 10px"
  },
  cancelButton: {
    color: "darkGray",
    width: "calc(50% - 2.5px)",
    minWidth: "80px",
    margin: "5px 5px 5px 0",
    "&:hover": {
      backgroundColor: "#fafafa",
    },
  },
  submitEditButton: {
    color: "white",
    backgroundColor: "#36ba8c",
    width: "calc(50% - 2.5px)",
    margin: "5px 0px",
    "&:hover": {
      backgroundColor: "#aee3d1",
    },
  },
  submitButton: {
    color: "white",
    backgroundColor: "#36ba8c",
    margin: "5px 10px",
    "&:hover": {
      backgroundColor: "#aee3d1",
    },
  },
  editButton: {
    color: "white",
    backgroundColor: "#36ba8c",
    width: "100%",
    margin: "5px auto",
    "&:hover": {
      backgroundColor: "#aee3d1",
    },
  },
  submissionWrapper: {
    padding: "0px 8px",
    margin: "10px -2px"
  },
  submissionFieldsWrapper: {
    display: "block",
    backgroundColor: "#ededed",
    padding: "5px 15px",
    marginBottom: "10px",
    borderRadius: "5px",
  },
  submissionFieldWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    padding: "0px",
    overflow: "hidden",
    margin: "5px auto"
  },
  submissionFieldPrompt: {
    padding: "0",
    textAlign: "left",
  },
  submissionFieldTextValue: {
    textAlign: "left",
    padding: "0px 10px",
    overflowX: "auto"
  },
  submissionFieldImageValue: {
    maxWidth: "100%",
    maxHeight: "650px",
  }
})
