import React from "react";
import { modalStyles } from "./styles.js";

const TeamMemberDetailRow = (props) => {
  const modalClasses = modalStyles();

  return (
    <div>
      <p>
        <div className={modalClasses.boldTitle}>{props.title}</div>
        <div className={modalClasses.modalData}>{props.data}</div>
      </p>
    </div>
  );
};

export default TeamMemberDetailRow;
