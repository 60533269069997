import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  tasks: null,
  moveInTasksCompleteModalVisible: false,
};

const moveInTasksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_TASKS:
      return { ...state, tasks: action.tasks };
    case types.SET_MOVE_IN_TASKS_COMPLETE_MODAL_VISIBLE:
      return {
        ...state,
        moveInTasksCompleteModalVisible: action.moveInTasksCompleteModalVisible,
      };
    default:
      return state;
  }
};

export default moveInTasksReducer;
