import React from "react";
import { connect } from "react-redux";
import { styles } from "./styles.js";
import { INITIAL_STATE } from "./reducers";
import moment from "moment";
import calendarIcon from "./img/date_range-24px.svg";

function MoveInHeader({ move }) {
  const classes = styles();

  function calculateCountdown() {
    if (move) {
      const moveTime = move.moveDate;
      const countdown = moment({ hours: 0 }).diff(moveTime, "days") * -1;
      return countdown;
    }
  }

  function countdownDisplay() {
    if (move) {
      const countdown = calculateCountdown();
      if (
        moment(move.moveDate).format("MMMM D, YYYY") ===
        moment().format("MMMM D, YYYY")
      ) {
        return <h1 className={classes.countdownText}>It's move-in day!</h1>;
      } else if (countdown <= 0) {
        return <h1 className={classes.countdownText}>Welcome Home!</h1>;
      } else {
        return (
          <div className={classes.countdownRow}>
            <div
              className={`${classes.countdownColumn} ${classes.countdownColumnRight}`}
            >
              <h1 className={classes.countdownNumber}>{countdown}</h1>
            </div>
            <div className={classes.countdownColumn}>
              <h3 className={classes.countdownTopText}>days until</h3>
              <h3 className={classes.countdownBottomText}>move-in</h3>
            </div>
          </div>
        );
      }
    }
  }

  function displayFormattedMoveInDate() {
    if (move) {
      const moveTime = move.moveDate;
      return moment(moveTime).format("dddd, MMMM D, YYYY");
    }
  }

  return (
    <div className={classes.halfScreenFlex}>
      <div>{countdownDisplay()}</div>
      <div>
        <p>
          <img
            src={calendarIcon}
            className={classes.alignImage}
            alt="calendar icon"
          />
          Move-in: {displayFormattedMoveInDate()}
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { RESIDENT_PAGE = INITIAL_STATE } = state;
  return {
    move: RESIDENT_PAGE.move,
  };
};

export default connect(mapStateToProps)(MoveInHeader);
