import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  amenityId: "",
  name: "",
  amenityLocation: undefined,
  description: undefined,
  availability: undefined,
  imageURL: undefined,
};

export const ResidentAmenityPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_AMENITY:
      return {
        ...state,
        amenityId: action.amenity.id,
        name: action.amenity.name,
        amenityLocation: action.amenity.location,
        description: action.amenity.description,
        availability: action.amenity.availability,
        imageURL: action.amenity.imageURL,
      };

    default:
      return state;
  }
};

export default ResidentAmenityPageReducer;
