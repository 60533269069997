const SET_SCREEN = "SET_SCREEN";
const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
const SIGN_OUT_USER = "SIGN_OUT_USER";
const SET_LABEL = "SET_LABEL";

export const types = {
  SET_SCREEN,
  SET_ERROR_MESSAGE,
  SIGN_OUT_USER,
  SET_LABEL,
};

export const setScreen = (screen) => ({
  type: SET_SCREEN,
  screen,
});

export const setErrorMessage = (error) => ({
  type: SET_ERROR_MESSAGE,
  error,
});

export const signOutUser = () => ({
  type: SIGN_OUT_USER,
});

export const setLabel = (label) => ({
  type: SET_LABEL,
  label,
});
