import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase } from "../../index";
import { types, setPhoneNumbers } from "./actions";

export function* FetchPhoneNumbers({ apartmentId }) {
  try {
    const phoneNumberRef = firebase.firestore.collection(
      `apartments/${apartmentId}/importantPhonenumbers/`
    );
    const phoneNumberDoc = yield call({
      context: phoneNumberRef,
      fn: phoneNumberRef.get,
    });

    function compare(a, b) {
      const phoneNumberA = a.title.toUpperCase();
      const phoneNumberB = b.title.toUpperCase();

      let comparison = 0;
      if (phoneNumberA > phoneNumberB) {
        comparison = 1;
      } else if (phoneNumberA < phoneNumberB) {
        comparison = -1;
      }
      return comparison;
    }

    const phoneNumberData = phoneNumberDoc.docs.map((doc) => doc.data());
    const sortedPhoneNumberData = phoneNumberData.sort(compare);
    if (phoneNumberData) {
      yield put(setPhoneNumbers(sortedPhoneNumberData));
    }
  } catch (error) {
    console.error("error with phoneNumber fetch " + error);
  }
}

export default function* PhoneNumbersSaga() {
  yield all([takeLatest(types.FETCH_PHONE_NUMBERS, FetchPhoneNumbers)]);
}
