export const FETCH_TASK = "FETCH_TASK"
export const SET_TASK = "SET_TASK"
export const SEND_TASK_COMPLETED = "SEND_TASK_COMPLETED"

export const fetchTask = (uid, taskId) => ({
  type: FETCH_TASK,
  uid,
  taskId
})

export const sendTaskCompleted = (uid, taskId, completed) => ({
  type: SEND_TASK_COMPLETED,
  uid,
  taskId,
  completed,
})

export const setTask = (task) => ({ type: SET_TASK, task })