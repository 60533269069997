import React, { useEffect, useState } from "react";
import { Button, TextField, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import {
  styles,
  textFieldInputStyles,
  buttonStyles,
  errorStyles,
} from "./styles.js";
import { INITIAL_STATE } from "./reducers";
import { setScreen, setErrorMessage, loginUser } from "./actions";
import ForgotPasswordModal from "./ForgotPasswordModal";

function LoginForm({
  goToInviteCode,
  goToResidentPage,
  user,
  error,
  clearError,
  loginUser,
}) {
  const classes = styles()();
  const textFieldClasses = textFieldInputStyles()();
  const buttonClasses = buttonStyles()();
  const errorClasses = errorStyles()();

  function signIn(event) {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    loginUser(email, password);
  }

  useEffect(() => {
    if (user && !user.isAnonymous) {
      goToResidentPage();
    }
  }, [user, goToResidentPage]);

  function toggleErrorVisibility() {
    if (error === "loginError") {
      return errorClasses.errorText;
    } else {
      return `${errorClasses.errorText} ${errorClasses.errorTextHidden}`;
    }
  }

  function toggleErrorTextStyling() {
    if (error === "loginError") {
      return `${textFieldClasses.errorformTextField} ${textFieldClasses.formTextField}`;
    } else {
      return textFieldClasses.formTextField;
    }
  }

  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  function handleForgotPasswordToggle() {
    setForgotPasswordVisible(!forgotPasswordVisible);
  }

  return (
    <div>
      <Button
        className={buttonClasses.button}
        variant="outlined"
        onClick={() => {
          clearError();
          goToInviteCode();
        }}
      >
        Enter your invite code
      </Button>
      <br />
      <div className={classes.orDividerWrapper}>
        <Divider className={classes.orDivider} />
        <p className={classes.orDividerText}>OR</p>
        <Divider className={classes.orDivider} />
      </div>
      <br />
      <form onSubmit={signIn}>
        <TextField
          id="email"
          label="Email Address"
          variant="filled"
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={toggleErrorTextStyling()}
        />
        <br />
        <TextField
          id="password"
          label="Password"
          variant="filled"
          type="password"
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{
            classes: {
              root: textFieldClasses.label,
              focused: textFieldClasses.focused,
            },
          }}
          className={toggleErrorTextStyling()}
        />
        <p className={toggleErrorVisibility()}>
          Oops! The email or password entered doesn't exist.
        </p>
        <Button
          className={`${buttonClasses.button} ${buttonClasses.submitButton}`}
          type="submit"
        >
          Log In
        </Button>
      </form>
      <p>
        <Button
          className={classes.passwordLink}
          onClick={() => {
            handleForgotPasswordToggle();
            clearError();
          }}
        >
          Forgot password?
        </Button>
      </p>
      <ForgotPasswordModal
        toggleErrorTextStyling={toggleErrorTextStyling}
        toggleErrorVisibility={toggleErrorVisibility}
        forgotPasswordVisible={forgotPasswordVisible}
        handleForgotPasswordClose={handleForgotPasswordToggle}
        error={error}
        clearError={clearError}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { LOGIN_PAGE = INITIAL_STATE, userState } = state;
  return {
    error: LOGIN_PAGE.error,
    user: userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToInviteCode: () => dispatch(setScreen("inviteCode")),
    goToResidentPage: () => dispatch(setScreen("resident")),
    clearError: () => dispatch(setErrorMessage("")),
    loginUser: (email, password) => dispatch(loginUser(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
