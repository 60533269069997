import { call, all, takeEvery, put } from "redux-saga/effects";
import { firebase } from "../../index";
import { types, setAmenity } from "./actions";

export function* FetchSelectedAmenity({ uid, amenityId }) {
  const amenity = yield call(
    [firebase, firebase.getUserApartmentAmenity],
    uid,
    amenityId
  );
  yield put(setAmenity(amenity));
}

export function* ResidentAmenityPageSaga() {
  yield all([takeEvery(types.FETCH_AMENITY, FetchSelectedAmenity)]);
}

export default ResidentAmenityPageSaga;
