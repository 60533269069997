import { call, put, all, takeLatest } from "redux-saga/effects";
import { firebase } from "../../index";
import { types, setAmenities } from "./actions";

export function* FetchAmenities({ apartmentId }) {
  try {
    const amenitiesRef = firebase.firestore.collection(
      `apartments/${apartmentId}/amenities/`
    );
    const amenitiesDoc = yield call({
      context: amenitiesRef,
      fn: amenitiesRef.get,
    });

    function compare(a, b) {
      const amenityA = a.name.toUpperCase();
      const amenityB = b.name.toUpperCase();

      let comparison = 0;
      if (amenityA > amenityB) {
        comparison = 1;
      } else if (amenityA < amenityB) {
        comparison = -1;
      }
      return comparison;
    }

    const amenitiesData = amenitiesDoc.docs.map((doc) => doc.data());
    const sortedAmenitiesData = amenitiesData.sort(compare);
    if (amenitiesData) {
      yield put(setAmenities(sortedAmenitiesData));
    }
  } catch (error) {
    console.error("error with amenities fetch " + error);
  }
}

export default function* AmenitiesSaga() {
  yield all([takeLatest(types.FETCH_AMENITIES, FetchAmenities)]);
}
