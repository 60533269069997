import React from 'react';
import { Typography } from '@material-ui/core';
import { TaskInfoRowStyles } from './styles';
import "./MoveInTaskPage.css"

const TaskInfoRow = ({ label, imageURL, icon, font }) => {
  const classes = TaskInfoRowStyles();
  const renderIcon = icon => {
    switch (icon) {
      case "error": return (
        <div className={classes.alignLargeIcon}>
          <i className={"material-icons alert"} >{icon}</i>
        </div>
      )
      case "check_box": return (
        <div className={classes.alignLargeIcon}>
          <i className={"material-icons completed"} >{icon}</i>
        </div>
      )
      default: return (
        <div className={classes.alignIcon}>
          <i className={"material-icons reg"}>{icon}</i>
        </div>
      )
    }
  }
  return (
    <Typography className={classes.root} variant={font} align="left">
      {renderIcon(icon)}
      <span className={font === "h6" ? classes.alignLargeText : classes.alignText}>
        {label}
      </span>
    </Typography>
  );
}

export default TaskInfoRow;