export const SET_SNACKS = "SET_SNACKS";
export const ADD_SNACK = "ADD_SNACK";
export const REMOVE_SNACK = "REMOVE_SNACK";

/**
 * set entire array of snacks to display
 * @param {[Object]} snacks 
 */
export const setSnacks = snacks => ({
  type: SET_SNACKS,
  snacks,
})

/**
 * add a snack to current array of snacks to display
 * @param {Object} snack 
 */
export const addSnack = (id, message, type) => ({
  type: ADD_SNACK,
  snack: {
    id, message, type,
  }
})

/**
 * remove a snack from current array of snacks to display
 * @param {string} snackId 
 */
export const removeSnack = snackId => ({
  type: REMOVE_SNACK,
  snackId
})