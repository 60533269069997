import { call, all, takeEvery, put } from "redux-saga/effects";
import moment from "moment";

import { firebase } from "../../index";
import { setTask, fetchTask } from "./actions";
import {
  // GET
  FETCH_TASK,

  // POST
  SEND_TASK_COMPLETED,
} from "./actions"

export function* fetchRequiredTask({ uid, taskId }) {
  const task = yield call([firebase, firebase.getUserResidentTask], uid, taskId)
  yield put(setTask(task))
}

export function* sendTaskCompleted({ uid, taskId, completed }) {
  try {
    const taskDoc = yield call(
      [firebase, firebase.getUserResidentTaskDocumentRef],
      uid, taskId
    );
    const completedDate = completed ? moment().valueOf() : 0
    yield call([taskDoc, taskDoc.set], { completedDate }, { merge: true });
    yield put(fetchTask(uid, taskId))

  } catch (error) {
    // TODO: ERROR MESSAGES
    console.error(error);
  }
}

export function* ResidentTaskPageSaga() {
  yield all([
    takeEvery(FETCH_TASK, fetchRequiredTask),
    takeEvery(SEND_TASK_COMPLETED, sendTaskCompleted),
  ])
}

export default ResidentTaskPageSaga;