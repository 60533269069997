import { types } from "./actions";

export const INITIAL_STATE = {
  error: "",
  screen: "login",
  inviteCode: null,
  confirmationVisible: false,
  termsUrl: null,
  privacyPolicyUrl: null,
  isLoading: false,
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SCREEN:
      return { ...state, screen: action.screen };
    case types.SET_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case types.SET_INVITE_CODE:
      return { ...state, inviteCode: action.inviteCode };
    case types.SET_CONFIRMATION_VISIBLE:
      return { ...state, confirmationVisible: action.confirmationVisible };
    case types.SET_TERMS_AND_PRIVACY_URLS:
      return {
        ...state,
        termsUrl: action.termsUrl,
        privacyPolicyUrl: action.privacyPolicyUrl,
      };
    case types.SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    default:
      return state;
  }
};

export default loginReducer;
